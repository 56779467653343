<template>
    <div class="leftpart poa pe pea bxz" >
        <div class="title" style="height: 8%;">
            <div class="cdmz">
                <img :src="require('../../assets/position_logo.png')" />
                <span style="color: #fff;font-size: 20px;;left: 1%;font-family: PingFangSC-Medium;">{{currentAddr.addrName}}</span>
            </div>
            <div class="device_sel pea bxz">
            </div>
        </div>
        <div class="header_manage df fdc jcc">
            <transition name="leftpart">
                <div class="device_list">
                    <div class="title device_manage" style="height:100%;overflow: hidden;">
                        <div class="device_manage_font">设备管理</div>
                        <v-row>
                            <v-col :span="12" class="manage_device_tab" style="border-right: 1px solid #5E7897;">
                                <img v-if="currentDevice == 1" :src="require('../../assets/kzsb.png')" />
                                <img v-else :src="require('../../assets/kz.png')" />
                                <span class="hov" :style="{
                                    'color':currentDevice == 1?'#19ECFF':'#5E7897',
                                    'font-family':currentDevice == 1?'PingFangSC-Medium':'PingFangSC-Regular'}" 
                                      @click="deviceChange(1)">控制类设备</span>
                            </v-col>
                            <v-col :span="12" class="manage_device_tab">
                                <img v-if="currentDevice == 2" :src="require('../../assets/chuang.png')" />
                                <img v-else :src="require('../../assets/chuangq.png')" />
                                <span class="hov" :style="{
                                    'color':currentDevice == 2?'#19ECFF':'#5E7897',
                                    'font-family':currentDevice == 2?'PingFangSC-Medium':'PingFangSC-Regular'
                                    }" 
                                      @click="deviceChange(2)">传感类设备</span>
                            </v-col>
                        </v-row>
                        <div v-if="device_list.length == 0" class="controllist_group_manage"></div>
<!--                        <VueSlickCarousel v-else-if="isControl" ref="carousel"-->
<!--                                          class="controllist_group_manage pea bxz control_list" v-bind="controlSetting">-->
<!--                            <div v-for="(item,index) in device_list" class="list_data_li">-->
<!--                                <p class="manage_switch hov" @click="swichPlay(item)" v-if="isControl">-->
<!--                                    <img v-if="item.isOn" :src="require('../../assets/kaiq.png')" />-->
<!--                                    <img v-else :src="require('../../assets/guan.png')" />-->
<!--                                </p>-->
<!--                                <div >-->
<!--                                    <v-row>-->
<!--                                        <v-col :span="5" class="fh PFSC-R">-->
<!--                                            设备名称-->
<!--                                        </v-col>-->
<!--                                        <v-col :span="12">-->
<!--                                            <div class="df circle_online_font PFSC-M" v-if="item.online">-->
<!--                                                <div class="circle_online"></div>-->
<!--                                                <span style="color: #27D370;">设备在线</span>-->
<!--                                            </div>-->
<!--                                            <div class="df circle_offline_font PFSC-M" v-else>-->
<!--                                                <div class="circle_offline"></div>-->
<!--                                                <span style="color: #5E7897;">设备离线</span>-->
<!--                                            </div>-->
<!--                                        </v-col>-->
<!--                                    </v-row>-->
<!--                                </div>-->
<!--                                <p class="slide_title PFSC-M">-->
<!--                                    {{item.title}}-->
<!--                                </p>-->
<!--                            </div>-->
<!--                        </VueSlickCarousel>-->
                        <swiper v-else-if="isControl"  :options="swiperOptions"
                                class="controllist_group_manage pea bxz control_list">
                            <swiper-slide v-for="(item,index) in device_list" class="list_data_li">
                                <p    class="manage_switch hov" @click="swichPlay(item)" v-if="isControl">
                                    <img v-if="item.isOn" :src="require('../../assets/kaiq.png')"/>
                                    <img v-else :src="require('../../assets/guan.png')"/>
                                </p>
                                <div>
                                    <v-row>
                                        <v-col :span="5" class="fh PFSC-R">
                                            设备名称
                                        </v-col>
                                        <v-col :span="12">
                                            <div class="df circle_online_font PFSC-M" v-if="item.online">
                                                <div class="circle_online"></div>
                                                <span style="color: #27D370;">设备在线</span>
                                            </div>
                                            <div class="df circle_offline_font PFSC-M" v-else>
                                                <div class="circle_offline"></div>
                                                <span style="color: #5E7897;">设备离线</span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                                <p class="slide_title PFSC-M">
                                    {{item.title}}
                                </p>
                            </swiper-slide>
                        </swiper>
                        <VueSlickCarousel v-else-if="!isControl" class="warninglist_group_manage pea bxz warning_list PFSC-R"
                                          v-bind="warningSetting">
                            <div v-for="(item,index) in Math.ceil(device_list.length / 6) " class="list_data_li">
                                <v-row>
                                    <v-col class="li_col" span="7" v-if="device_list[index * 6]">
                                        <div class="fw df">
                                            <v-popover trigger="click"  placement="right">
                                                <img :src="device_list[index * 6].icon">
                                                <div slot="content"><p class="pop_title PFSC-R">{{device_list[index * 6].title}}</p></div>
                                            </v-popover>
                                            <div class="fl df pos" v-if="device_list[index * 6].online">
                                                <div class="circle_online "></div>
                                                设备在线
                                            </div>
                                            <div class="fr df pos" v-else>
                                                <div class="circle_offline"></div>
                                                设备离线
                                            </div>
                                        </div>
                                        <p>
                                            <span class="fw">电量：</span>
                                            <span :class="device_list[index * 6].powerText == '电量正常'?'fl':'fr'">
                                                {{device_list[index * 6].powerText}}</span>
                                        </p>
                                        <p v-for="(k,v) in device_list[index * 6].extraList">
                                            <span class="fw">{{k.keyTitle}}：</span>
                                            <span class="fw">{{k.keyValue}}</span>
                                        </p>
                                    </v-col>
                                    <v-col class="li_col" span="7" v-if="device_list[index * 6 + 1]">
                                        <div class="fw df">
                                            <v-popover trigger="click"  placement="right">
                                                <img :src="device_list[index * 6 + 1].icon">
                                                <div slot="content"><p class="pop_title PFSC-R">{{device_list[index * 6 + 1].title}}</p></div>
                                            </v-popover>
                                            <div class="fl df pos" v-if="device_list[index * 6 + 1].online">
                                                <div class="circle_online"></div>
                                                设备在线
                                            </div>
                                            <div class="fr df pos" v-else>
                                                <div class="circle_offline"></div>
                                                设备离线
                                            </div>
                                        </div>
                                        <p>
                                            <span class="fw">电量：</span>
                                            <span :class="device_list[index * 6 + 1].powerText == '电量正常'?'fl':'fr'">{{device_list[index * 6 + 1].powerText}}</span>
                                        </p>
                                        <p v-for="(k,v) in device_list[index * 6 + 1].extraList">
                                            <span class="fw">{{k.keyTitle}}：</span>
                                            <span class="f">{{k.keyValue}}</span>
                                        </p>
                                    </v-col>
                                    <v-col class="li_col" span="7" v-if="device_list[index * 6 + 2]">
                                        <div class="fw df">
                                            <v-popover trigger="click"  placement="right">
                                                <img :src="device_list[index * 6 + 2].icon">
                                                <div slot="content"><p class="pop_title PFSC-R">{{device_list[index * 6 + 2].title}}</p></div>
                                            </v-popover>
                                            <div class="fl df pos" v-if="device_list[index * 6 + 2].online">
                                                <div class="circle_online"></div>
                                                设备在线
                                            </div>
                                            <div class="fr df pos" v-else>
                                                <div class="circle_offline"></div>
                                                设备离线
                                            </div>
                                        </div>
                                        <p>
                                            <span class="fw">电量：</span>
                                            <span :class="device_list[index * 6 + 2].powerText == '电量正常'?'fl':'fr'">{{device_list[index * 6 + 2].powerText}}</span>
                                        </p>
                                        <p v-for="(k,v) in device_list[index * 6 + 2].extraList">
                                            <span class="fw">{{k.keyTitle}}：</span>
                                            <span class="fw">{{k.keyValue}}</span>
                                        </p>
                                    </v-col>
                                </v-row>
                                <v-row v-if="device_list[index * 6 + 3] || device_list[index * 6 + 4] || device_list[index * 6 + 5]">
                                    <v-col class="li_col" span="7" v-if="device_list[index * 6 + 3]">
                                        <div class="fw df">
                                            <v-popover trigger="click"  placement="right">
                                                <img :src="device_list[index * 6 + 3].icon">
                                                <div slot="content"><p class="pop_title PFSC-R">{{device_list[index * 6 + 3].title}}</p></div>
                                            </v-popover>
                                            <div class="fl df pos" v-if="device_list[index * 6 + 3].online">
                                                <div class="circle_online"></div>
                                                设备在线
                                            </div>
                                            <div class="fr df pos" v-else>
                                                <div class="circle_offline"></div>
                                                设备离线
                                            </div>
                                        </div>
                                        <p>
                                            <span class="fw">电量：</span>
                                            <span :class="device_list[index * 6 + 3].powerText == '电量正常'?'fl':'fr'">{{device_list[index * 6 + 3].powerText}}</span>
                                        </p>
                                        <p v-for="(k,v) in device_list[index * 6 + 3].extraList">
                                            <span class="fw">{{k.keyTitle}}：</span>
                                            <span class="fw">{{k.keyValue}}</span>
                                        </p>
                                    </v-col>
                                    <v-col class="li_col" span="7" v-if="device_list[index * 6 + 4]">
                                        <div class="fw df">
                                            <v-popover trigger="click"  placement="right">
                                                <img :src="device_list[index * 6 + 4].icon">
                                                <div slot="content"><p class="pop_title PFSC-R">{{device_list[index * 6 + 4].title}}</p></div>
                                            </v-popover>
                                            <div class="fl df pos" v-if="device_list[index * 6 + 4].online">
                                                <div class="circle_online"></div>
                                                设备在线
                                            </div>
                                            <div class="fr df pos" v-else>
                                                <div class="circle_offline"></div>
                                                设备离线
                                            </div>
                                        </div>
                                        <p>
                                            <span class="fw">电量：</span>
                                            <span :class="device_list[index * 6 + 4].powerText == '电量正常'?'fl':'fr'">{{device_list[index * 6 + 4].powerText}}</span>
                                        </p>
                                        <p v-for="(k,v) in device_list[index * 6 + 4].extraList">
                                            <span class="fw">{{k.keyTitle}}：</span>
                                            <span class="fw">{{k.keyValue}}</span>
                                        </p>
                                    </v-col>
                                    <v-col class="li_col" span="7" v-if="device_list[index * 6 + 5]">
                                        <div class="fw df">
                                            <v-popover trigger="click"  placement="right">
                                                <img :src="device_list[index * 6 + 5].icon">
                                                <div slot="content"><p class="pop_title PFSC-R">{{device_list[index * 6 + 5].title}}</p></div>
                                            </v-popover>
                                            <div class="fl df pos" v-if="device_list[index * 6 + 5].online">
                                                <div class="circle_online"></div>
                                                设备在线
                                            </div>
                                            <div class="fr df pos" v-else>
                                                <div class="circle_offline"></div>
                                                设备离线
                                            </div>
                                        </div>
                                        <p>
                                            <span class="fw">电量：</span>
                                            <span :class="device_list[index * 6 + 5].powerText == '电量正常'?'fl':'fr'">{{device_list[index * 6 + 5].powerText}}</span>
                                        </p>
                                        <p v-for="(k,v) in device_list[index * 6 + 5].extraList">
                                            <span class="fw">{{k.keyTitle}}：</span>
                                            <span class="fw">{{k.keyValue}}</span>
                                        </p>
                                    </v-col>
                                </v-row>
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>
            </transition>
        </div>
        <div class="leftbottom_manage pea bxz" v-if="webcamShow">
            <div v-if="webcamIndex > 0" class="web-pre" @click="clickPre">
                <div class="arrow-left"></div>
            </div>
            <div v-if="webcamIndex < webcams.length - 1" class="web-next" @click="clickNext">
                <div class="arrow-right"></div>
            </div>
            <div style="height: 13%;"><div class="webcam_font">监控视频</div></div>
            <div class="manage_capture" @click="clickisshowbutton">
                    <img :src="webcam.capture"/>
            </div>
        </div>
    
        <!-- <div v-if="ezopenShow">
            <EZUIKitJs  :token="ezopenObj.accessToken" :url="ezopenObj.url" :full="ezopenObj.isFull"/>
        </div> -->
    </div>
</template>
<script>
    //carousel
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import EZUIKitJs from '@/components/EZUIKitJs'
    import {areaList, deviceList, listWithCount, refresh, onOrOff, detail,addrList} from '@/api/screen'
    import { getMsToken } from '../../utils/auth';
    import {ezopen, webcamlist} from "../../api/screen";
    import {createLogger, mapState} from "vuex";
    import 'swiper/dist/css/swiper.css'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'


        
        
        
    let socket;
    export default {
        
        name: 'componentB',
        props: ['currentAddr'],
        components: {
            VueSlickCarousel,
            EZUIKitJs,
            swiper,
            swiperSlide
        },
        data() {
            return {
                tname:'junmoxiao',
                check: false,
                webcam: null,
                currentSite: '',     //设备场地
                sites: [],
                addrs: [],
                swiperOptions:{
                    direction: 'vertical',
                    slidesPerView: 4,
                    loop:false,
                    spaceBetween: 10,
                    autoplay:{
                        delay: 20000
                    },
                    
                },
                controlSetting: {                 //滚动设置
                    infinite: true,
                    arrows: false,
                    dots: false,
                    rows: 1,
                    vertical: true,
                    slidesToScroll: 1,
                    slidesToShow: 5,
                    autoplay: true,
                    autoplaySpeed: 20000,
                    focusOnSelect:false,
                    touchMove:true,
                    swipeToSlide:true,
                    draggable:true,
                    touchThreshold:5,
                },
                warningSetting: {
                    infinite: true,
                    arrows: true,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 20000,
                    rows: 1,
                    slidesPerRow: 1
                },
                isControl: true,
                deviceTypes: [{label: '控制设备', value: '1'}, {label: '传感设备', value: '2'}],
                deviceTypesIndex: {},   //记录设备类型下标
                currentTypeIndex: 0,
                typeInterval: '',
                device_list: [],
                currentDevice: 1,
                weather: {},
                air: {},
                weatherInterval: '',
                catIds: [],
                webcamShow:false,
                webcamIndex: 0,
                webcams: [],
                webcamInterval:'',
                ezopenObj:{},
                ezopenShow:false,
            }
        },
           async created(){
                const _this = this;
                let {data} = await addrList();
                //存储当前场地的id
                _this.$store.commit('setAddrs', data);

          //在页面加载时读取sessionStorage里的状态信息
          if(JSON.parse(sessionStorage.getItem(_this.$store.state.addrId))!=null){

            _this.$store.state.inNum = JSON.parse(sessionStorage.getItem(_this.$store.state.addrId)).inNum;
            _this.$store.state.outNum = JSON.parse(sessionStorage.getItem(_this.$store.state.addrId)).outNum;
            _this.$store.state.sum = JSON.parse(sessionStorage.getItem(_this.$store.state.addrId)).sum;
          

          }

},
        methods: {
            clickisshowbutton(){
         
            
             let t=this;
             console.log('8888===>',t.$store.state.webcam)
             let param = {id: t.$store.state.webcam.id};
            
                ezopen(param).then(res => {
                    if (res.data) {
                        // t.ezopenObj = res.data;
                        t.$store.state.ezopenObj=res.data
                        console.log('点击图片播放======》',t.$store.state.ezopenObj)
                        // t.ezopenObj.isFull = false;
                        // t.ezopenShow = true;
                    }
                })
            setTimeout(()=>{
                this.$store.state.isshow=true;
            },500)
          

            // main.style.display='block'
             //鼠标按下
              setTimeout(() => {
            let main=document.querySelector('.main')
            let closeBtn=document.querySelector('.close-btn')
            // 点击隐藏悬浮框和mask蒙层
            let tt=this;
            closeBtn.addEventListener('click',function(){
            // tt.stopscreen();
            tt.$store.state.isshow=false;
         
            // tt.$refs['child'].$emit('handleChange');

        })

            main.addEventListener('mousedown',function(event){
            // 获得鼠标在盒子里的坐标
            // console.log(event.pageX)
            // console.log(main.offsetLeft)
            let x=event.pageX-main.offsetLeft
            let y=event.pageY-main.offsetTop
            document.addEventListener('mousemove',move)
            // 也可以吧document换成main都行
            function move(event){
                // 盒子距离body的距离=鼠标距离body的距离-鼠标距离盒子的距离
                main.style.left=event.pageX-x+'px'
                main.style.top=event.pageY-y+'px'
            }
            // 鼠标抬起
            document.addEventListener('mouseup',function(){
                document.removeEventListener('mousemove',move)
            })
        })
              
                }, 1000)




            },

            confirm: function() {
                this.$message.info('点击了是');
            },
            cancel: function() {
                this.$message.info('点击了否');
            },
            clickPre() {
                const t = this;
                t.webcamIndex--;
                t.webcam = t.webcams[t.webcamIndex];
                t.$store.state.webcam = t.webcams[t.webcamIndex];
                console.log('摄像机编号---------->',t.$store.state.webcam.code)
                // let param = {id: t.webcams[t.webcamIndex].id};
                // ezopen(param).then(res => {
                //     if (res.data) {
                //         t.webcam = res.data;
                //     }
                // })
            },
            clickNext() {
                const t = this;
                t.webcamIndex++;
                t.webcam = t.webcams[t.webcamIndex];
                t.$store.state.webcam = t.webcams[t.webcamIndex];
                 console.log('摄像机编号---------->',t.$store.state.webcam.code)
            },
            //查看实时大屏
            capFull(){
                const t = this;
                let param = {id: t.webcam.id};
                ezopen(param).then(res => {
                    if (res.data) {
                        t.ezopenObj = res.data;
                        t.ezopenObj.isFull = true;
                        t.ezopenShow = true;
                    }
                })
            },
            // 摄像头列表
            async getWebcamList() {
                const t = this;
                clearInterval(t.webcamInterval);
                t.webcamShow = false;
                t.webcamIndex = 0;
                let param = {addrId: this.currentAddr.id, areaId: ''};
                // console.log('param=====',param)
                // console.log('addddddd=======',this.$store.state.addrId)
                let {data} = await webcamlist(param)   
                t.webcams = data;
                if (data.length > 0) {
                    t.webcam = data[t.webcamIndex];
                    t.$store.state.webcam=data[t.webcamIndex];
                    t.webcamShow = true;
                }
               
                
                data.forEach(item => {
 
                    if(item.iscountcam){
                    //显示人流统计界面
                    t.$store.state.ifview=true;
                    // t.initsocket() 
                    }else{
                    t.$store.state.ifview=false;
                    }
                  
                    
                })


                t.webcamInterval = setInterval(()=>{
                    t.getWebcamList();
                    console.log('执行了定时器')
                },300000);

                 
            },
            async clickRefresh(item, index) {
                item.isLoad = true;
                this.$set(this.device_list, index, item);
                const t = this;
                let param = {
                    id: item.id
                }
                item.isLoad = true;
                try {
                    let {data} = await refresh(param);
                    if (data) {
                        item.isLoad = true;
                        detail({id: item.id}).then(res => {
                            if (res.code == 0) {
                                item = res.data
                                item.isLoad = false;
                                t.$set(this.device_list, index, item);
                                t.$message['success']('刷新成功')
                            }
                        })
                    }
                } catch (e) {
                    item.isLoad = false;
                    this.$set(this.device_list, index, item);
                }
            },
            async swichPlay(item) {
                if(JSON.parse(sessionStorage.getItem('user')).isControlState){
                    this.$message.warning("该用户无控制权限,请联系管理员！")
                    return;
                };
                item.isOn = !item.isOn;
                let param = {id: item.id}
                try {
                    let {code} = await onOrOff(param);
                    if (code == 0) {
                        this.$message['success']('操作成功')
                    }
                } catch {
                    item.isOn = !item.isOn;
                }
            },
            siteChange(value) {
                this.getDeviceList(this.currentDevice);
            },
            deviceChange(value) {
                this.currentDevice = value;
                this.getDeviceList(value);
            },
            async getDeviceType() {
                const t = this;
                clearInterval(t.typeInterval);
                // t.deviceTypes = [];
                t.catIds = [];
                t.deviceTypesIndex = {};
                t.currentDevice = 1;
                t.currentTypeIndex = 0;
                let param = {
                    addrId: this.$store.state.addrId,
                    type: 1
                }
                let param2 = {
                    addrId: this.$store.state.addrId,
                    type: 2
                }
                let {data} = await listWithCount(param);
                data.forEach((item, index) => {
                    t.catIds.push({label: item.title, value: item.id, type: item.type});
                    t.deviceTypesIndex[item.id] = item;
                })
                listWithCount(param2).then(res => {
                    if (res.code == 0) {
                        res.data.forEach(item => {
                            t.catIds.push({label: item.title, value: item.id, type: item.type});
                            // t.deviceTypes.push({label: item.title, value: item.id,type:item.type});
                            t.deviceTypesIndex[item.id] = item;
                        })
                    }
                })
                if (t.deviceTypes.length > 1) {
                    t.typeInterval = setInterval(() => {
                        if (t.currentTypeIndex == t.deviceTypes.length - 1) {
                            t.currentTypeIndex = 0;
                        } else {
                            t.currentTypeIndex++;
                        }
                    }, 1200000);          //20分钟切换一次
                }

            },
            async getArea(addrId, catId) {
                const t = this;
                let param = {addrId: addrId, catId: catId};
                let {data} = await areaList(param);
                t.sites = [];
                data.forEach((item, index) => {
                    // if (index == 0) {
                    //     t.currentSite = item.id;
                    // }
                    let option = {
                        label: item.areaName,
                        value: item.id,
                    }
                    t.sites.push(option);
                })
                if (data.length == 0) {
                    t.currentSite = '';
                }
                // this.getDeviceList(catId);
            },
            async getDeviceList(type) {
                const t = this;
                t.deviceData = [];
                t.device_list = [];
                if (!type) return;
                let catIds = [];
                t.catIds.forEach(item => {
                    if (item.type == type) {
                        catIds.push(item.value);
                    }
                })
                let params = {
                    addrId: this.$store.state.addrId,
                    areaId: this.currentSite,
                    catId: '',
                    catIds: [],
                    type:type
                }
                let {data} = await deviceList(params)
                data.forEach((v)=>{
                    switch (v.catIdDicText) {
                        case '位移传感器':
                            v.icon = require('../../assets/wy.png');
                            break;
                        case '光照温湿度':
                            v.icon = require('../../assets/yg.png');
                            break;
                        case '土壤温湿度':
                            v.icon = require('../../assets/tur.png');
                            break;
                        default:
                            v.icon = require('../../assets/wy.png');
                    }
                    v.extraList.forEach((m)=>{
                        switch (m.keyName) {
                            case 'humidity':
                                m.keyValue = m.keyValue + '%';
                                m.keyTitle = '湿度';
                                break;
                            case 'temp':
                                m.keyValue = m.keyValue + '℃';
                                m.keyTitle = '温度';
                                break;
                            case 'ec':
                                m.keyValue = m.keyValue + 'mS/cm';
                                m.keyTitle = 'EC值';
                                break;
                            case 'light':
                                m.keyValue = m.keyValue + 'lx';
                                m.keyTitle = '光照值';
                                break;
                                
                        }
                    })
                })
                t.device_list = data;
                this.isControl = type == '1' ? true : false;
            },
        },
        mounted() {

        },
        computed: {
            ...mapState([
                'isfull',
            ]),
        },

        watch: {
            'isfull':function(){
                if(!this.isfull){
                    this.ezopenShow =false;
                }
            },
            'currentAddr': function () {
                if (this.currentAddr.id) {
                    const t = this;
                    clearInterval(t.weatherInterval);
                     t.getWebcamList();
                     t.getDeviceType();
                     t.getDeviceList(t.currentDevice);
                     t.weatherInterval = setInterval(() => {
                        t.getWeather();
                    }, 3600000);                 
  
                }
            },
        },
    }
</script>
<style lang="scss">
        .main{
            /* 因为下面要用到所以绝对定位 */
            position: absolute;
            top:25%;
            left: 50%;
            transform: translate(-50%,0);
            width: 500px;
            height: 450px;
            // background-color: skyblue;
            text-align: center;
            // line-height: 300px;
            // display: none;
            z-index: 100;
        }
            .close-btn{
            position: absolute;
            right: -20px;
            top: -20px;
            width: 50px;
            height: 50px;
            line-height: 50px;
            border-radius: 50%;
            background-color: #ccc;
            color: #ffffff;
        }


    .leftpart {
        color: #fff;
        z-index: 20;
        padding-left: 20px;
        width: calc(31% + 11px);
        height: 85%;
        top: 12%;
        overflow-y: auto;
        overflow-x: hidden;

        .header_manage {
            z-index: 5;
            font-size: 50px;
            height: 55%;
            letter-spacing: 0em;
            background: linear-gradient(to left, #F6FFD1 0%, #28E0A8 100%);
            -webkit-background-clip: text;
            color: transparent;
            filter: drop-shadow(0 4px 2px rgba(#000, .4));

            .sub {
                font-size: .35em;
                letter-spacing: .1em;
                font-weight: 100;
                text-transform: uppercase;
                background: inherit;
                opacity: .75;
            }
        }

        .device_list {
            .device_manage{
                background: url("../../assets/bg_5.png") no-repeat;
                background-size: 100% 100%;
                font-size: 20px;
                color: #fff;
            }
            .device_manage_font{
                font-size: 11px;text-align: center;color: #19ECFF;line-height: 20px;top: 5px;height: 60px;font-family: PingFangSC-Medium;
            }
            .manage_device_tab{             
                text-align: center;
                font-size: 16px;
                img{
                    width:16px;
                    top: 5px;
                    margin: 0 5px;
                }
            }
            /*height: 100%;*/
            .control_list {
                margin: auto;
                .slick-list {
                    /*max-height: 300px;*/
                }
                /*.slick-track{*/
                /*    height: auto!important;*/
                /*}*/
            }

            .warning_list {
                .slick-slide {
                    padding-left: 5px;
                }

                .slick-next {
                    right: 0%;
                    width: 100%;
                    top: calc(90% - 6px) !important;
                    background: url("../../../src/assets/da_up.svg") no-repeat center;
                    color: #28e0a8;
                    transform: rotate(180deg);
                    z-index: 6;

                    &:before {
                        content: '';
                    }

                    height: 40px;
                }

                .slick-prev {
                    height: 40px;
                    width: 100%;
                    left: 0%;
                    top: 5% !important;
                    background: url("../../../src/assets/da_up.svg") no-repeat center;
                    color: #28e0a8;

                    &:before {
                        content: '';
                    }
                }
            }

            .warninglist_group_manage {
                /*min-height: 100%;*/
                height: 100%;
                /*min-height: 84%;*/
                width: 100%;
                font-size: 14px;
                padding: 1% 1%;

                .slick-list {
                    max-height: 100%;
                }

                .list_data_li {
                    
                    margin: 2px 0;
                    width: 98%;
                    height: auto;
                    padding: 5px 0 0 5px;
                    position: relative;
                    .ant-row {
                        height: 170px;                       
                        padding: 5px;
                    }

                    .li_col {
                        padding: 15px 10px 0px 10px;
                        margin: 0 5px;
                        background: url("../../assets/bg_3.png") no-repeat;
                        background-size: 100% 100%;
                        height: 100%;
                        line-height: 20px;
                        img {
                            top: -5px;
                        }
                        .ant-popover-arrow{
                            display: none;
                        }
                        .ant-popover-inner{
                            background: linear-gradient(180deg, #43484E 0%, #292B30 76%, #131419 100%);   
                            width: 100px;
                        }
                        .pop_title{
                            color: #fff;
                        }
                    }
                    .pos{
                        top: 7px;
                        left: 10%;
                    }

                    .circle_online{
                        width: 5px;
                        height: 5px;
                        border-radius: 3px;
                        background-color: #27D370;
                        top: 7px;
                        margin: 0 5px;
                    }
                    .circle_offline{
                        width: 5px;
                        height: 5px;
                        border-radius: 3px;
                        background-color: #5E7897;
                        top: 7px;
                        margin: 0 5px;
                    }
                    .fw {
                        color: white;
                    }

                    .fr {
                        color: #5E7897;
                    }

                    .fl {
                        color: #27D370;
                    }
                }

                .manage_refresh {
                    z-index: 10;
                    position: absolute;
                    bottom: 1vh;
                    right: 5px;

                    .ant-btn:focus, .ant-btn:hover {
                        color: #28e0a8;
                    }

                    .ant-btn {
                        border-radius: 1em;
                    }

                    img {
                        transform: rotate(90deg);
                        vertical-align: middle;
                    }
                }

                .manage_switch {
                    z-index: 10;
                    position: absolute;
                    right: 5px;
                    .ant-switch-checked {
                        background-color: #28e0a8;
                    }

                }
            }

            .it {
                width: 100%;
            }

            .title {
                font-size: 20px;
                color: #fff;
                .tar {
                    font-weight: normal;
                    opacity: .5;
                }

                span {
                    width: 100%;
                }

                .cdmz {
                    img {width: 24px;top: 5px;}
                    span {margin-left: 5px;}
                }

                .vb {
                    background-color: rgb(40, 224, 168);
                    color: rgb(40, 224, 168);
                    font-size: 15px;
                    border-radius: 5px;
                }

                .vb_manage {
                    background-color: rgb(40, 224, 168);
                    color: rgb(40, 224, 168);
                    font-size: 12px;
                    border-radius: 5px;
                }

                .device_sel {
                    display: flex;
                    margin-top: 2vh;

                    .ant-select-selection__clear {
                        display: none !important;
                    }

                    .ant-select-selection {
                        border-radius: 0px !important;
                        border: 2px solid #28e0a8 !important;
                        background-color: rgba(40, 224, 168, .2) !important;
                    }

                    .ant-select-selection__rendered {
                        color: white;
                    }

                    .ant-select-dropdown, .ant-select-dropdown-menu {
                        background-color: rgba(40, 224, 168, .2) !important;
                    }

                    .ant-select-search__field__placeholder, .ant-select-selection__placeholder {
                        color: #fff;
                    }

                    .ant-select-arrow {
                        left: 40% !important;
                        background: url("../../../src/assets/arrow-down.png") no-repeat center;
                        color: #28e0a8;

                        &:after {
                            content: '';
                            color: #28e0a8;
                        }
                    }

                    .dslo {
                        width: 46%;
                    }

                    .dsgap {
                        width: 8%;
                        opacity: 0;
                        height: 1vh;
                    }
                }

                .controllist_group_manage {
                    width: 96%;
                    height: 70%;
                    font-size: 14px;
                    padding: 0 1%;
                    margin-top: 4%;

                    .list_data_li {
                        background: rgba(#28e0a8, 0.2);
                        padding: 5px 15px;
                        position: relative;
                        /*height: 100%;*/
                        height: 100%;
                        background: url("../../assets/bg_3.png");
                        background-size: 100% 100%;
                        .slide_title {
                            margin: 5px 0;
                            font-size: 20px;
                            color: white;
                        }
                        .fh {
                            color: #5E7897;
                            font-size: 12px;
                        }
                        .circle_online_font{
                            font-size: 12px;
                            color:#27D370;
                        }
                        .circle_offline_font{
                            font-size: 12px;
                            color:#5E7897;
                        }

                        .fl {
                            color: #28e0a8;
                        }
                        .circle_online{
                            width: 5px;
                            height: 5px;
                            border-radius: 3px;
                            background-color: #27D370;
                            top: 7px;
                            margin: 0 5px;
                        }
                        .circle_offline{
                            width: 5px;
                            height: 5px;
                            border-radius: 3px;
                            background-color: #5E7897;
                            top: 7px;
                            margin: 0 5px;
                        }
                    }

                    .manage_refresh {
                        z-index: 10;
                        position: absolute;
                        bottom: 1vh;
                        right: 5px;

                        .ant-btn:focus, .ant-btn:hover {
                            color: #28e0a8;
                        }

                        .ant-btn {
                            border-radius: 1em;
                        }

                        img {
                            transform: rotate(90deg);
                            vertical-align: middle;
                        }
                    }

                    .manage_switch {
                        z-index: 10;
                        position: absolute;
                        right: 5px;
                        img {
                            top: 15px;
                            right: 20px;
                            width: 80px;
                        }
                        .ant-switch-checked {
                            background-color: #28e0a8;
                        }

                    }
                }
            }
        }

        .leftbottom_manage {
            height: calc(34% - 4px);
            padding: 5px;
            margin-top: 1vh;
            background: url("../../assets/bg_5.png") no-repeat;
            background-size: 100% 100%;
            z-index: 10;
            overflow: hidden;
            .audio-controls {
                position: absolute;
                top: 78%;
                display: block;
            }

            .webcam_font{
                font-size: 11px;
                text-align: center;
                color: #19ECFF;
                line-height: 10px;
                top: 5px;
                height: 40px;
                font-family: PingFangSC-Medium;
            }
            .web-pre {
                width: 3em;
                height: 3em;
                background: rgba(0, 0, 0, 0.6);
                position: absolute;
                border-radius: 2em;
                color: #fff;
                top: 50%;
                left: 3%;
                z-index: 10;

                .arrow-left {
                    position: relative;
                    top: 27%;
                    left: 31%;
                    width: 20px;
                    height: 20px;
                    border-top: 3px solid #fff;
                    border-right: 3px solid #fff;
                    transform: rotate(-135deg);
                }
            }

            .web-next {
                width: 3em;
                height: 3em;
                background: rgba(0, 0, 0, 0.6);
                position: absolute;
                border-radius: 2em;
                color: #fff;
                top: 50%;
                right: 3%;
                z-index: 10;

                .arrow-right {
                    position: relative;
                    top: 27%;
                    left: 12%;
                    width: 20px;
                    height: 20px;
                    border-top: 3px solid #fff;
                    border-right: 3px solid #fff;
                    transform: rotate(45deg);
                }
            }
            .manage_capture{
                text-align: center;
                height: 79%;
                img{
                    width:94%;
                    /*max-height: 14.5em;*/
                    height: 100%;
                    object-fit:cover;
                }
            }
        }
    }

    .leftpart::-webkit-scrollbar //滚动条整体部分
    {
        width: 0px;
    }

    .loading-animation-box {
        margin: 0;
        padding: 0;

        .loading-animation {
            animation: loading 0.75s linear infinite forwards;
        }

        @keyframes loading {
            0% {
                transform: rotate(0deg)
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

</style>

