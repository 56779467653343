<template>
 
    <div id="video-container" style="width: 101%;height: 100%;"/>
    <!-- <span class="fs" @click="fullscreen">
     <img src="../../assets/red-round.svg" style="vertical-align: middle;"/>展开全屏
    </span> -->

</template>

<script>
import EZUIKit from 'ezuikit-js'

export default {
  props: {
    msg: String,
    token: String,
    url: String,
    full:Boolean,
  },
  data() {
    return {
      player: null,
      params: {
        autoplay: true,
        id: 'video-container',
        template: 'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        accessToken: '',
        url: '',
        // 视频上方头部控件
        header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
        plugin: ['talk'],                       // 加载插件，talk-对讲
        // 视频下方底部控件
        footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
        // audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
        // openSoundCallBack: data => console.log("开启声音回调", data),
        // closeSoundCallBack: data => console.log("关闭声音回调", data),
        // startSaveCallBack: data => console.log("开始录像回调", data),
        // stopSaveCallBack: data => console.log("录像回调", data),
        // capturePictureCallBack: data => console.log("截图成功回调", data),
        // fullScreenCallBack: data => console.log("全屏回调", data),
        // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
        // width: '',
        height: '350px',
        fullScreenChangeCallBack:data=>this._changeStore('isfull',data.data),
        closeSoundCallBack:data=>console.log('关闭回调执行了000000'),
        handleSuccess:data=>console.log('成功播放回调执行了0000000'),
      }
    }
  },
  created() {
    this.params.accessToken = this.token
    this.params.url = this.url
  },
  mounted() {
    this.player = new EZUIKit.EZUIKitPlayer(this.params)
    console.log(this.full,'=====full');
    if(this.full){
      this.player.fullScreen();
    }
  },
  methods:{
    fullscreen(){
      this.player.fullScreen();
    }
  },
}
</script>
<style lang="scss">
  .fs{
    img{
      width: 7px;
      margin-right: 4px;
    }
    position: relative;top: -15%;left: 70%;padding: 3px 10px;
    background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
    &:hover{
      cursor: pointer;
    }
  }
</style>

