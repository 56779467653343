import request from '@/utils/request'


// 获取前端token
export function getPublicToken(data) {
    return request({
        url: '/api/v1/user/user/getFrontToken',
        method: 'post',
        data
    })
}

//退出登录
export function exitLogin(){
    return request({
        url:'/api/v1/user/user/logout',
        method:'post'
    })
}

// 场地数据
export function addrList(){
    return request({
        url:'/api/v1/addr/addr/listAll',
        method:'post',
    })
}

//设备类型数据
export function listWithCount(data){
    return request({
        url:'/api/v1/device/catalog/list-with-count',
        method:'post',
        data
    })
}

//区域数据
export function areaList(data){
    return request({
        url:'/api/v1/addr/area/list',
        method:'post',
        data
    })
}

// 设备列表
export function deviceList(data){
   return request({
        url:'/api/v1/device/device/list',
        method:'post',
        data
    })
}

//7日天气数据
export function weatherDaliy(data){
    return request({
        url:'/api/v1/data/weather/gw7d',
        method:'post',
        data
    })
}

//当天天气
export function weatherNow(data){
    return request({
        url:'/api/v1/data/weather/now',
        method:'post',
        data
    })
}
// 空气质量
export function airNow(data){
    return request({
        url:'/api/v1/data/weather/air-now',
        method:'post',
        data
    })
}

//折线图数据
export function chartLine(data){
    return request({
        url:'/api/v1/chart/line',
        method:'post',
        data
    })
}

// 获取WebCamAccessToken
export function ezopen(data){
    return request({
        url:'/api/v1/webcam/ezopen',
        method:'post',
        data
    })
}

//Webcam 地址
export function webCamUrl(data){
    return request({
        url:'/api/v1/webcam/addr',
        method:'post',
        data
    })
}

//摄像头列表
export function webcamlist(data){
    return request({
        url:'/api/v1/webcam/list',
        method:'post',
        data
    })
}

//预警数据
export function warningRecod(data){
    return request({
        url:'/api/v1/alert/record/screenList',
        method:'post',
        data
    })
}

//刷新设备
export function refresh(data){
    return request({
        url:'/api/v1/device/device/refresh',
        method:'post',
        data
    })
}

//设备详情
export function detail(data){
    return request({
        url:'/api/v1/device/device/screen-detail',
        method:'post',
        data
    })
}

//设备开关
export function onOrOff(data){
    return request({
        url:'/api/v1/device/device/on-off',
        method:'post',
        data
    })
}

//空气质量
export function airQuality(data){
    return request({
        url:'/api/v1/data/weather/air-now',
        method:'post',
        data
    })
}
// 设备运行状态
export function runStatus(data){
    return request({
        url:'/api/v1/device/device/run-status',
        method:'post',
        data
    })
}

//一周用水/用电量
export function useAboutOneWeek(data) {
    return request({
        url:'/api/v1/chart/bar',
        method:'post',
        data
    })
}

// 登录
export function login(data){
    return request({
        url:'/api/v1/user/user/login',
        method:'post',
        data
    })
}

// 碳中和
export function cnrData(data){
    return request({
        url:'/api/v1/chart/cnrData',
        method:'post',
        data
    })
}
