<template>
    <div id="body" class="body df fdc" v-if="show">
        <p class="pt_name">{{currentAddr.screenName}}</p>
        <bg-map :addrs="addrs"></bg-map>
        <div class="light"></div>
        <left-part :current-addr="currentAddr" @deviceevent="deviceEvent"></left-part>
        <right-part :current-addr="currentAddr" :device-param="deviceParam"></right-part>
        <div class="bottom-warning" v-if="warningShow">
            <v-carousel autoplay :vertical="settings.vertical"
                        :autoplay-speed="60000"
                        :dots="settings.dots"
                        :arrow="settings.arrow">
                <v-carousel-item v-for="item in warningList">
                    <div class="demo-carousel">{{item}}</div>
                </v-carousel-item>
            </v-carousel>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import {mapState} from 'vuex'
    import bgMap from './bgMap.vue'
    import leftPart from './leftpart'
    import rightPart from "./rightpart";
    import '../../scss/base.scss';
    import { getPublicToken , addrList} from "@/api/screen";
    import {getPsToken, removeMsToken, setPsToken} from "../../utils/auth";
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

    export default {
        name: 'publicScreen',
        components: {
            bgMap,
            leftPart,
            rightPart,
            VueSlickCarousel
        },
        data(){
            return {
                addrs:[],
                timer_id:0,  // 倒计时 ID
                time:null,
                date:null,
                show:false,
                deviceParam:{},
                warningShow:true,
                warningInterval:'',
                reloadAddrInterval:'',
                warningList:[],
                settings: {                 //滚动设置
                    dots:'none',
                    arrow:'never',
                    vertical:true
                },
            }
        },
        methods:{
            async getToken(){
                let body = {
                    "password": "DCvaE$%12J",
                    "username": "CloudDotFront"
                }
                let {data} = await getPublicToken(body)
                if(data.token){
                    setPsToken(data.token);
                }
                this.show = true;
                this.getAddr();
            },
            async getAddr(){
                const t =this;
                clearInterval(t.warningInterval);
                clearInterval(t.reloadAddrInterval);
                t.warningShow = true;
                t.warningList = [];
                let {data} = await addrList();
                t.$store.commit('setAddrs',data);
                if (t.$route.query.addrid) {
                    t._changeStore('addrId',t.$route.query.addrid);
                    data.forEach(item=>{
                        if(item.id == t.$route.query.addrid){
                            t.$store.commit("setCurrentAddr",item)
                            t.warningList.push(item.warning)
                            if(!item.screenLogo){
                                t.currentAddr.screenLogo = require('../../assets/logo.png');
                            }
                            if(!item.screenName){
                                t.currentAddr.screenName = '里水市政数字管理平台'
                            }
                        }
                    })
                }else{
                    if(data.length > 0){
                        t._changeStore('addrId',data[0].id);
                        t._changeStore("currentAddr",data[0]);
                        t.warningList.push(data[0].warning)
                        if(!data[0].screenLogo){
                            t.currentAddr.screenLogo = require('../../assets/logo.png');
                        }
                        if(!data[0].screenName){
                            t.currentAddr.screenName = '里水市政数字管理平台'
                        }
                    }

                }
                t.addrs = data;
                if(t.warningList.length == 0){
                    t.warningInterval = setInterval(()=>{
                        t.warningShow = false;
                    },120000)
                }
                t.reloadAddrInterval = setInterval(()=>{
                    t.getAddr();
                },600000)
            },
            deviceEvent(param){
                this.deviceParam = param;
            },
        },
        computed:{
            ...mapState([
                'currentAddr',
                'addrId',
            ]),
        },
        watch:{
            currentAddr:function () {
                if(!this.currentAddr.screenLogo){
                    this.currentAddr.screenLogo = require('../../assets/logo.png');
                }
                if(!this.currentAddr.screenName){
                    this.currentAddr.screenName = '里水市政数字管理平台';
                }

                this.warningList = [];
                this.warningList.push(this.currentAddr.warning);
            }
        },
        created() {
            removeMsToken();
            if(!getPsToken()){
                this.getToken();
            }else{
                this.getAddr();
                this.show = true;
            }
            if (this.$route.query.addrid) {
                this.$store.commit('setAddrId',this.$route.query.addrid);
            }
        },
    }
</script>
<style lang="scss" scoped>
    .light{z-index: 1;position: absolute;left: 50%;display: block;width: 100%;height: 15%;background: #56ff7a80;transform:translate(-50%,-75%);border-radius: 50%;filter:blur(20vh);}

    .body{width: 100vw;height: 100vh;overflow: hidden;}
    .txt_green{color: #28E0A8;}
    .txt_cyan{color: #00D6D6;}
    .pt_name {width: 100%;font-size: 4em;color:#28E0A8;position: absolute;z-index: 10;top: 3vh;text-align: center;}
    .container{z-index: 5;padding: 0 40px 20px 40px;}

    .part{width: 100%;}

    .air{
        padding-bottom: 20px;height: 140px;line-height: 1;border-bottom: 1px solid rgba(#fff,.2);
        // +.farm{order:-1}
    }
    .weather{padding: 12px 0;width: 50%;/*height: 100px; border-bottom: 1px solid rgba(#fff,.2); */}
    .rain{
        width: 100%;
        .name{
            padding-left: 1em;font-size: 24px;line-height: 3em;
            &:before{content:'';position: absolute;left: 0;top: 50%;margin-top: -.5em;display: block;height: 1em;width: .2em;background: #28E0A8;border-radius: .2em;}
        }
    }
    .three{display: block; background:rgba( #000,0);}

    .qrcode{
        padding: 12px;display: block;width: 1em;height: 1em;font-size: 200px;line-height: 0;overflow: hidden;border-radius: 50%;background: #fff;box-shadow: 0 0 0 16px rgba(0,0,0,.2);
        img{width: 100%;}
    }
    .rain~.qrcode{bottom: 100px;border-radius: 50%;}

    @keyframes rotate360{
        0%{transform:rotate(0);transform-origin: center;}
        100%{transform: rotate(360deg);}
    }

    .rightpart-enter-active, .rightpart-leave-active,.leftpart-enter-active, .leftpart-leave-active {transition: all .5s ease-out; }
    .rightpart-enter, .rightpart-leave-to {transform:translateX(100%);opacity: 0; }
    .leftpart-enter, .leftpart-leave-to {transform:translateX(-100%);opacity: 0; }
    .bottom-warning {
        position: fixed;height: 5%;width: 100%;
        background-color: rgba(0,0,0,0.5);color: #3CB371;font-size: 2em;text-align: center;
        vertical-align: middle;bottom: 0;z-index: 6;
        }
</style>
<style lang="scss">
    .ant-message-notice-content{
        position: fixed !important;
    }
</style>

