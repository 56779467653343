import cryptoJs from 'crypto-js';

const BS_KEY = "aENHN51656E46E"

//DES加密
export const encryptDes = (message) => {
    var keyHex = cryptoJs.enc.Utf8.parse(BS_KEY)
    var option = { mode: cryptoJs.mode.ECB, padding: cryptoJs.pad.Pkcs7 }
    var encrypted = cryptoJs.DES.encrypt(message, keyHex, option)
    return encrypted.ciphertext.toString()
}


//DES解密
export const decryptDes = (message) => {
    var keyHex = cryptoJs.enc.Utf8.parse(BS_KEY)
    var decrypted = cryptoJs.DES.decrypt(
        {
            ciphertext: cryptoJs.enc.Hex.parse(message)
        },
        keyHex,
        {
            mode: cryptoJs.mode.ECB,
            padding: cryptoJs.pad.Pkcs7
        }
    )
    return decrypted.toString(cryptoJs.enc.Utf8)
}
