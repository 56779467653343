import Vue from 'vue'
import VueRouter from 'vue-router'
import publicScreen from '../views/publicScreen/index'
import manageScreen from '../views/manageScreen/index'
import logo from '../views/login/index'

Vue.use(VueRouter)

const routes = [
    {
        path: '/publicScreen',
        name: 'publicScreen',
        component: publicScreen
    },
    {
        path: '/manageScreen',
        name: 'manageScreen',
        component: manageScreen
    },
  {
        path: '/login',
        name: 'login',
        component: logo
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
