<template>
    <div class="manage_rightpart df fdc ais bxz">
        <div class="rh PFSC-R">
            <div class="rh_time">{{nowDate}}</div>
        </div>
        <div class="manage_sstq">
            <div class="tac sstq_title">
                实时天气
            </div>
            <v-row class="sstq_height PFSC-R">
                <v-col :span="10">
                    <div class="temp_num PFSC-M">
                    <div >
                           <img class="svg_icon-inner" :src="img" alt="" width="60px" height="60px"   >
                    </div>
                        <div style="display: inline-block;margin-right: 40px;margin-top: 20px;">
                             {{weather.temp}}<span class="unit">°</span>
                        </div>
                        <div class="temp txt_green num" style="display: inline-block;">
                        <span class="name">{{weather.text}}</span>
                          
                    </div>
                  
                    </div>
                   
                </v-col>
                <v-col :span="12" style="height: 100%">
                    <v-row style="height: 50%;padding: 10% 0;">
                        <v-col :span="11" class="wind" style="height: 50%">
                            <div class="df">
                                <div>
                                    <img :src="require('../../assets/fx.png')"/>
                                </div>
                                <div class="sstq_img_jj">
                                    <div> {{weather.windDir}}</div>
                                    <div>{{weather.windScale}}级</div>
                                </div>
                            </div>


                        </v-col>
                        <v-col :span="13" class="wind" style="height: 50%">
                            <div class="df">
                                <div>
                                    <img :src="require('../../assets/pm.png')"/>
                                </div>
                                <div class="sstq_img_jj">
                                    <div>PM2.5</div>
                                    <div>{{air.category}}&nbsp;{{air.pm2p5}}/ug/m3</div>
                                </div>
                            </div>


                        </v-col>
                    </v-row>
                    <v-row style="height: 50%;padding: 10% 0;">
                        <v-col :span="11" class="wind" style="height: 50%">
                            <div class="df">
                                <div>
                                    <img :src="require('../../assets/jiangs.png')"/>
                                </div>
                                <div class="sstq_img_jj">
                                    <div>降水量</div>
                                    <div>{{weather.precip}}&nbsp;毫米</div>
                                </div>
                            </div>


                        </v-col>
                        <v-col :span="13" class="wind" style="height: 50%">
                            <div class="df">
                                <div>
                                    <img :src="require('../../assets/shidu.png')"/>
                                </div>
                                <div class="sstq_img_jj">
                                    <div>相对湿度</div>
                                    <div>{{weather.humidity}}%</div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <div class="manage_warning" v-show="warningShow" :key="1">
            <v-icon class="manage_icon_close" type="close-circle-o" @click.native="closeWarning"></v-icon>
            <div style="font-weight: 500;">预警信息</div>
            <div class="df">
                <div style="width: 50%">设备名称：{{recordInfo.devName}}</div>
                <div style="width: 50%">场地名称：{{recordInfo.addrName}}</div>
            </div>
            <div>设备分类：{{recordInfo.cateName}}</div>
            <div>原因：{{recordInfo.content}}</div>
        </div>
        <div class="manage_w_e df">
            <div class="chart" >
                <div class="tac" style="color: #19ECFF;font-family: PingFangSC-Medium;">用水量</div>
                <line-chart v-if="waterChartIsLoad" :data="waterChart.data" :option="waterChart.option"
                            :height="'100%'" :key="componentKey"></line-chart>
            </div>
            <div class="chart" style="margin-left: 2%;">
                <div class="tac" style="color: #19ECFF;font-family: PingFangSC-Medium;">用电量</div>
                <line-chart v-if="elecChartIsLoad" :data="elecChart.data" :option="elecChart.option"
                            :height="'100%'" :key="componentKey"></line-chart>
            </div>
        </div>
        <div class="manage_leftbottom pea bxz">
            <div class="tac" style="color: #19ECFF;font-family: PingFangSC-Medium;">碳排放/碳吸收</div>
            <bar-chart v-if="cnrChart.data" :data="cnrChart.data" :option="cnrChart.option" :key="componentKey"></bar-chart>
        </div>
    </div>
</template>
<script>
    import {
        airQuality,
        weatherDaliy,
        weatherNow,
        warningRecod,
        useAboutOneWeek,
        cnrData
    } from "../../api/screen"
    import barChart from "../../components/echarts/bar_chart";
    import lineChart from "../../components/echarts/line_chart";
    //引入基本模板
    let echarts = require('echarts/lib/echarts')



    
    export default {
        props: ['currentAddr', 'areaId'],
        components: {
            barChart,
            lineChart,
        },
        data() {
            return {
                air: {},
                warningShow: false,
                nowDate: null,
                datetimer: '',
                weather: {},
                waterChart: {},
                waterChartIsLoad: false,
                elecChart: {},
                elecChartIsLoad: false,
                cnrChart: {},
                next7days: [],
                chartInterval: '',
                weatherInterval: '',
                recordInfo: {},
                recordInterval: '',
                img:'',
                componentKey: 0,
            }
        },
        mounted() {
            this.initDate();
            this.weatherInterval = setInterval(() => {
                this.getWeather();
            }, 3600000)
            
            window.addEventListener('resize', () => {
                
                var height=document.body.clientHeight
                // console.log('屏幕高度',height)
            if (height === 1080) {

            // console.log('触发全屏事件')
                    this.componentKey += 1; 
            } else {
                    this.componentKey += 1; 
                

}

})
        },
        methods: {
            getAir() {
                const t = this;
                let param = {
                    id: this.currentAddr.id
                }
                airQuality(param).then(res => {
                    if (res.code == 0) {
                        t.air = res.data.now;
                    }
                })

            },
            jumtback() {
                window.location.href = this.$back_url;
            },
            closeWarning() {
                this.warningShow = false;
            },
            async getWeather() {
                const t = this;

                let param = {id: t.currentAddr.id};
                let {data} = await weatherNow(param);
                this.weather = data.now;
                this.img=require('../../assets/icons/'+this.weather.icon+'-fill.svg')
            },
            //星期转换
            fmtWeek(week) {
                // const weekDate = week.substr(0, 10)
                const weekFmt = '日一二三四五六'.charAt(new Date(week).getDay())
                return '周' + weekFmt;
            },
            initDate() {
                const t = this;
                t.datetimer = setInterval(() => {
                    let nowDate = t.date2str(new Date(), 'yyyy/MM/dd hh:mm');
                    let week = '日一二三四五六'.charAt(new Date(nowDate).getDay());
                    let dateAry = nowDate.split(' ');
                    t.nowDate = dateAry[0] + ' 周' + week + ' ' + dateAry[1];
                }, 1000);
            },
            //日期转换
            date2str(x, y) {
                let z = {
                    y: x.getFullYear(),
                    M: x.getMonth() + 1,
                    d: x.getDate(),
                    h: x.getHours(),
                    m: x.getMinutes(),
                    s: x.getSeconds()
                };
                return y.replace(/(y+|M+|d+|h+|m+|s+)/g, function (v) {
                    return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-(v.length > 2 ? v.length : 2))
                });

            },
            //日期转换   2021-09-25 00:00:00 >>> 09/25(六)
            fmtLineTime(time) {
                let date = this.date2str(new Date(time), "MM/dd")
                let week = '日一二三四五六'.charAt(new Date(date).getDay());
                // return date + "(" + week + ")";
                return "(" + week + ")";

            },

            //预警信息
            async getWarningRecord() {
                const t = this;
                clearInterval(t.recordInterval);
                let {data} = await warningRecod({customerId: t.currentAddr.customerId})
                if (data.length > 0) {
                    t.recordInfo = data[0];
                }
                t.recordInterval = setInterval(() => {
                    t.getWarningRecord();
                }, 1800000)

            },
            //一周用水用电图
            async getUseBarData(keyName) {
                const t = this;
                let {data} = await useAboutOneWeek({addrId: t.currentAddr.id, keyName: keyName});
                t.fmtChartList(keyName, data);
            },
            fmtChartList(type, list) {
                const t = this;
                let data = {};
                data.xData = [];
                data.yData = [];
                list.forEach((item,index) => {
                    if(index % 2 == 0){
                        data.xData.push(t.fmtWeek(item.date));
                    }else{
                        data.xData.push('');
                    }
                    data.yData.push((item.value));
                })
                switch (type) {
                    case 'water':
                        t.waterChart = {};
                        data.sName = '';
                        data.eName = '单位：吨';
                        data.id = 'water_1';
                        data.lineColor = '#F27C4A';
                        data.min = 0;
                        // data.max = 8;
                        data.splitNumber = 4;
                        data.boundaryGap = [0,1]
                        data.unit = '';
                        t.waterChart.data = data;
                        t.waterChartIsLoad = true;
                        break;
                    case 'elec':
                        t.elecChart = {};
                        data.sName = '';
                        data.eName = '单位：/千瓦时';
                        data.id = 'elec_1';
                        data.lineColor = '#32FF7D';
                        data.min = 0;
                        // data.max = 200;
                        data.splitNumber = 4;
                        data.boundaryGap = [0,1];
                        data.unit = '';
                        t.elecChart.data = data;
                        t.elecChart.key = t.elecChart.index == 1 ? 0 : 1;
                        t.elecChartIsLoad = true;
                        break;
                }
            },
            async getCNR() {
                const t = this;
                let {data} = await cnrData({addrId: t.currentAddr.id})
                let cnr = [];
                data.dates.forEach((item, index) => {
                    let obj = {};
                    // obj.date = t.fmtLineTime(item);
                    obj.date = t.fmtWeek(item);
                    obj.碳排放 = data.ce[index];
                    obj.碳吸收 = data.cn[index];
                    cnr.push(obj);
                })
                t.fmtBarLine(cnr, data);
            },
            fmtBarLine(data, data2) {
                const t = this;
                data.sName = '';
                data.eName = '';
                data.id = 'db_bar_1';
                let option = {
                    color:['#D8BD03','#F36F1A'],
                    grid: {
                        top: "32%",
                        left: '5%',
                        right: '0%',
                        bottom: '0%',
                        // x: "12%",//x 偏移量
                        // y: "7%", // y 偏移量
                        width: "90%", // 宽度
                        height: "55%",// 高度
                        //是否显示刻度标签
                        containLabel: true,
                    },
                    legend: {
                        right: 0,
                        top: '5%',
                        itemHeight: 15,
                        itemWidth: 15,
                        textStyle: {
                            color: '#fff',
                        },
                        selectedMode: false,
                    },
                    tooltip: {},
                    dataset: {
                        dimensions: ['date', '碳排放', '碳吸收'],
                        source: data
                    },
                    xAxis: {
                        axisLabel: {
                            // rotate: 15,
                            rotate: 0,
                            textStyle: {
                                color: '#29EEFF'
                            }
                        },

                        color: '#06B5B4',
                        type: 'category',
                        splitLine: {
                            lineStyle: {
                                color: '#06B5B4',
                                width: 1,
                                type: 'solid',
                            },
                            show: false,
                        },
                    },
                    yAxis: {
                        // min:0,
                        // max:6,
                        splitNumber: 4,
                        splitLine: {
                            lineStyle: {
                                width: 1,
                                type: 'solid'
                            },
                            show: false,
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#29EEFF'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                width: 1,
                                type: 'solid',
                            },
                            show: true,
                        },
                    },
                    series: [
                        {
                            type: 'bar',
                            barWidth: 10,
                            itemStyle: {
                                normal: {
                                    borderWidth: 1,
                                    barBorderRadius: [15, 15, 0, 0],
                                    color: {
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: 'rgba(234, 203, 0,0.7)' // 0% 处的颜色
                                            },
                                            {
                                                offset: 1,
                                                color: 'rgba(229, 201, 3, 0.09)' // 100% 处的颜色
                                            }
                                        ],
                                        global: false // 缺省为 false
                                    },
                                    shadowBlur: 5,
                                    shadowColor: 'rgba(255, 255, 255, 0.18000000715255737)',
                                    shadowOffsetX: 0,
                                    shadowOffsetY: -2
                                },
                                symbol: 'circle',
                                symbolSize: ['15', '9'],
                                symbolPosition: 'end',
                                z: 3
                            }
                        }, {
                            type: 'bar', barWidth: 10,
                            itemStyle: {
                                normal: {
                                    borderWidth: 1,
                                    barBorderRadius: [15, 15, 0, 0],
                                    color: {
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: 'rgba(252, 115, 26, 0.7)' // 0% 处的颜色
                                            },
                                            {
                                                offset: 1,
                                                color: 'rgba(228, 116, 35, 0.06)' // 100% 处的颜色
                                            }
                                        ],
                                        global: false // 缺省为 false
                                    },
                                    shadowBlur: 5,
                                    shadowColor: 'rgba(255, 255, 255, 0.18000000715255737)',
                                    shadowOffsetX: 0,
                                    shadowOffsetY: -2
                                },
                                symbol: 'circle',
                                symbolSize: ['15', '9'],
                                symbolPosition: 'end',
                                z: 3
                            }
                        }]
                }
                let barLine = {};
                barLine.option = option;
                barLine.data = data;
                t.cnrChart = barLine;
            },

        },
        watch: {
            'currentAddr': function () {
                if (this.currentAddr.id) {
                    this.getWeather();
                    this.getAir();
                    this.getWarningRecord();
                    this.waterChartIsLoad = false;
                    this.elecChartIsLoad = false;
                    this.getUseBarData('water');
                    this.getUseBarData('elec');
                    this.getCNR();
                }
            },
        }
    }
    //  天气图标  32
</script>
<style lang="scss">
    .manage_rightpart {
        z-index: 20;
        position: absolute;
        padding-right: 20px;
        right: 0;
        height: 100%;
        width: calc(30% + 11px);

        &::-webkit-scrollbar {
            display: none;
        }

        .rh {
            z-index: 5;
            font-size: 20px;
            height: 19%;
            color: #fff;
            text-align: right;

            img {
                width: 24px;
                vertical-align: middle;
                margin-right: 5px;

            }

            .rh_time {
                font-size: 14px;
                margin-top: 21%;
                height: 100%;
            }
        }

        .manage_warning {
            padding: .5em;
            width: 100%;
            height: 10%;
            background: linear-gradient(to bottom, rgba(220, 20, 60, 0.2), rgba(255, 0, 0, 0.4) 45%, rgba(255, 0, 0, 0.7));
            font-size: 1em;
            color: #fff;
            font-weight: 200;

            .manage_icon_close {
                position: absolute;
                right: 1vw;
                top: 1vh;
                font-size: 2em;
                z-index: 10;
            }
        }

        .manage_w_e {
            width: 100%;
            height: 29%;
            margin-top: .2em;

            .chart {
                width: 49%;
                height: 100%;
                background: url("../../assets/bg_5.png") no-repeat;
                background-size: 100% 100%;
            }
        }

        .vb {
            background-color: rgb(40, 224, 168);
            color: rgb(40, 224, 168);
            border-radius: 3px;
            width: 3px;
            height: 15px;
        }

        .manage_cgqqx {
            color: #fff;
            height: 30%;
        }

        .manage_leftbottom {
            overflow: hidden;
            height: 22%;
            background: url("../../assets/bg_4.png");
            background-size: 100% 100%;
            padding: 5px;
            margin-top: 1vh;
            z-index: 10;
            color: #fff;

            .audio-controls {
                position: absolute;
                top: 78%;
                display: block;
            }

            .vb {
                background-color: rgb(40, 224, 168);
                color: rgb(40, 224, 168);
                font-size: 12px;
                border-radius: 4px;
            }
        }

        .manage_sstq {
            background: url("../../assets/bg_4.png") no-repeat;
            background-size: 100% 100%;
            height: 24%;
            padding: 1%;
            color: #fff;

            .sstq_height {
                height: 88%;
            }

            .sstq_title {
                font-size: 11px;
                color: #19ECFF;
                font-family: PingFangSC-Medium;
            }

            .unit {
                margin-left: .25em;
                vertical-align: super;
                font-size: .5em;
            }

            .temp_num {
                img {
                    top: 10px;
                }

                text-align: center;
                /*font-size: 34px;*/
                font-size: 30px;
                font-weight: 600;
            }

            .temp {
                font-size: 24px;
                line-height: 0.9em;
                text-align: center;
                left: -25px;
            }

            .wind {
                font-size: 13px;

                img {
                    width: 33px;
                    top: 6px;
                }

                .sstq_img_jj {
                    margin-left: 10px;
                }
            }

            .nextseven {
                margin-top: 0.7em;

                .n7w_week {
                    font-size: 1.1em;
                    font-weight: bold;
                    text-align: center;
                }

                .n7w_time {
                    font-size: 0.8em;
                    color: #fff;
                    opacity: 0.7;
                    text-align: center;
                }

                .n7w_sit {
                    font-size: 1.1em;
                    font-weight: 400;
                    text-align: center;
                }

                .ant-col-3 {
                    width: 13.5% !important;
                }
               
            }
        }
    }


</style>
