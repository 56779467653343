<template>
    <div class="barC">
        <v-row>
            <v-col span="12" class="yn tal">{{data.sName}}</v-col>
            <v-col span="12" class="yn2 tar">{{data.eName}}</v-col>
        </v-row>
        <div v-bind:id="data.id" style="width: 100%;height:100%;"></div>
    </div>
</template>
<script>
    // 引入柱状图等
    require('echarts/lib/chart/bar');
    require('echarts/lib/chart/pictorialBar');
    require("echarts/lib/chart/line");
    require("echarts/lib/chart/pie");
    // 引入legend组件
    require("echarts/lib/component/legend");
    //引入基本模板
    let echarts = require('echarts/lib/echarts')
    export default {
        props: ['data','option'],
        data() {
            return {}
        },
        methods: {
            init() {
                const t = this;
                let chartDom = document.getElementById(t.data.id);
                let myChart = echarts.init(chartDom);
                let option = {};
                if(t.option){
                    option = t.option
                }else{
                    option = {
                        color: '#28e0a8',
                        grid: {
                            top: "30%",
                            left: '5%',
                            right: '0%',
                            bottom: '0%',
                            // x: "12%",//x 偏移量
                            // y: "7%", // y 偏移量
                            width: "85%", // 宽度
                            height: "100%",// 高度
                            //是否显示网格
                            show: true,
                            //是否显示刻度标签
                            containLabel: true,
                            borderColor: 'green',
                        },
                        barWidth: 30,
                        xAxis: {
                            axisLabel: {
                                rotate: 20,
                            },
                            type: 'category',
                            splitLine: {
                                lineStyle: {
                                    color: '#06B5B4',
                                    width: 1,
                                    type: 'solid',
                                },
                                show: true,
                            },
                            data:t.data.xData
                        },
                        yAxis: {
                            type: 'value',
                            min: t.data.min,
                            max: t.data.max,
                            splitNumber: t.data.splitNumber,
                            splitLine: {
                                lineStyle: {
                                    color: '#06B5B4',
                                    width: 1,
                                    type: 'solid'
                                },
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#06B5B4',
                                    width: 1,
                                    type: 'solid',
                                },
                                show: true,
                            },
                        },
                        series: [
                            {
                                data:t.data.yData,
                                type: 'bar'
                            }
                        ]
                    };
                }
                option && myChart.setOption(option);
            },
        },
        mounted() {
            this.init();
        },
        watch:{
            'data':function(){
                if(this.data){
                    this.init();
                }
            }
        }
    }
</script>
<style lang="scss">
    .barC {
        height:100%;
        .yn {
            font-size: 1.2em;
            position: absolute;
            top: 1vh;
        }

        .yn2 {
            font-size: 1em;
            position: absolute;
            top: 1vh;
            right: 0;
        }
    }
</style>
