<template>
    <div class="container_map fwh" id="map" ref="map"></div>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
    import {initMapV2} from "../../utils/bdmap";

    export default {
        name: 'bgMap',
        props: {
            addrs: {type: Array, default: [],},
            currentAddr: {type: Object}
        },
        data() {
            return {
                mapCenter: [113.168726, 23.037597],
                zoomSize: 10,
                view: null,
                data: [],
                map: null,
            }
        },
        methods: {
            initMap() {
                const self = this;
                let map = this.map = initMapV2({
                    id: 'map',
                    zoom: self.zoomSize || 16,
                    center: self.mapCenter || [113.168726, 23.037597],
                });
                let myIcon;
                map.setDefaultCursor('grab');
                // if (self.currentAddr && self.currentAddr.icon) {
                //     myIcon = new BMapGL.Icon(self.currentAddr.icon, new BMapGL.Size(50, 60));
                // } else {
                    myIcon = new BMapGL.Icon(require('../../../public/static/ic_marker_position.png'), new BMapGL.Size(50, 60));
                // }
                self.addrs.forEach(item => {
                    if (item.longitude && item.latitude) {
                        self.addMarker(item, myIcon);
                    }
                })
            },
            addMarker(item, myIcon) {
                const t = this;
                let p = new BMapGL.Point(item.longitude, item.latitude);
                let marker = new BMapGL.Marker(p, {icon: myIcon})
                t.map.addOverlay(marker);
                marker.addEventListener('click', function (e) {
                    t.clickMarker(e, item)
                })

                // this.markers[item.attr.type].data.push(p)
            },
            clickMarker(e, item) {
                const t = this;
                let pos = e.target.getPosition();
                let opts = {
                    width: 350,     // 信息窗口宽度
                    height:100,     // 信息窗口高度
                    // message:"<div>设备数量:16</div><div>主控编号:AE86</div><div>在线设备:1</div><div>离线设备:15</div>"
                }
                if(item.isinstallgateway){
                    let content = `
                    <div class="map_store_info tal pe">
                       <div class="store_title"><div class="ttl"></div>` + item.addrName + `</div>
                       <div class="df">
                        <div class="store_name" style="width: 50%;">
                            <div>设备数量：` + item.devCount + `</div>
                             <div class="store_name">在线设备：` + item.onlineCount + `</div>
                       </div>
                       <div class="store_name" style="width: 50%;">
                      <div class="store_name">主控编号：` + item.masterId + `</div>
                        <div class="store_name">离线设备：` + item.offlineCount + `</div>
                       </div>
                    </div>     
                    </div>`;
                console.log('当前场地类型',item.isinstallgateway)
   
                let infoWindow = new BMapGL.InfoWindow(content, opts);  // 创建信息窗口对象
                t.map.openInfoWindow(infoWindow, pos);
                // 
                // setTimeout(() => {
                //     t.map.panTo(pos);
                // }, 500)
                t._changeStore('addrId', item.id);
                t._changeStore('currentAddr', item);   

                }else{
                    let content = `
                    <div class="map_store_info tal pe">
                       <div class="store_title"><div class="ttl"></div>` + item.addrName + `</div>
                       <div class="df">
                        <div class="store_name" style="width: 50%;">
                            <div>设备数量：` + item.devCount + `</div>
                             <div class="store_name">在线设备：` + item.onlineCount + `</div>
                       </div>
                       <div class="store_name" style="width: 50%;">
                        <div class="store_name">离线设备：` + item.offlineCount + `</div>
                       </div>
                    </div>     
                    </div>`;
              
   
                let infoWindow = new BMapGL.InfoWindow(content, opts);  // 创建信息窗口对象
                t.map.openInfoWindow(infoWindow, pos);
                // 
                // setTimeout(() => {
                //     t.map.panTo(pos);
                // }, 500)
                t._changeStore('addrId', item.id);
                t._changeStore('currentAddr', item);   
                    
                } 

            },
            getBase64Image(img) {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, img.width, img.height);
                let ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
                let dataURL = canvas.toDataURL("image/" + ext);
                return dataURL;
            },
            async getShareImg(img, item, myIcon) {
                const t = this;
                let image = new Image();
                image.setAttribute("crossOrigin", 'anonymous')
                image.src = img + '?v=3';
                image.onload = () => {
                    let base64 = t.getBase64Image(image);
                    myIcon = new BMapGL.Icon(base64, new BMapGL.Size(50, 50));
                    let p = new BMapGL.Point(item.longitude, item.latitude);
                    t.addMarker(item, myIcon);
                    t.map.setCenter(p);
                }
            },
           
        },
        mounted() {
            this.initMap()
        },
        watch: {
            'addrs': function () {
                const t = this;
                t.map.clearOverlays();
                let myIcon = new BMapGL.Icon(require('../../../public/static/ic_marker_position.png'), new BMapGL.Size(50, 60));
                this.addrs.forEach(item => {
                    if (item.longitude && item.latitude) {
                        if (item.id == t.$store.state.addrId) {
                            // if (item.icon) {
                            //     t.getShareImg(item.icon, item, myIcon);
                            //     // myIcon.setImageUrl(item.icon);
                            // } else {
                                let p = new BMapGL.Point(item.longitude, item.latitude);
                                t.addMarker(item, myIcon);
                                t.map.setCenter(p);
                            // }
                        } else {
                            // if (item.icon) {
                            //     t.getShareImg(item.icon, item, myIcon);
                            // } else {
                                let p = new BMapGL.Point(item.longitude, item.latitude);
                                t.addMarker(item, myIcon);
                                t.map.setCenter(p);
                            // }
                        }
                    }

                });
            },
        },
    }
</script>
<style lang="scss">
    .container_map {
        display: block;

        .shadow {
            visibility: hidden;
        }
    }

    .anchorBL {
        opacity: 0;
    }

    .BMap_bubble_content {
        overflow: visible !important;
    }

    .BMap_bubble_pop {
        visibility: hidden;
    }

    .map_store_info {
        left: -30px;
        top: -30px;
        padding: 20px 16px 16px 16px;
        font-size: 15px;
        visibility: visible;
        color: #F4B110;
        /*background: linear-gradient(0, rgba(0, 0, 0, .75), transparent);*/
        background: rgba(2, 28, 57, 0.81);
        border-radius: 8px;
        filter: drop-shadow(0 1px rgba(#23FFF9, 1));
        border: 1px solid #23FFF9;

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 100%;
            display: block;
            border-radius: 0 0 4px 0;
            transform: translate(-50%, -50%) rotate(45deg);
            border: 8px solid rgba(0, 0, 0, .75);
            border-top-color: transparent;
            border-left-color: transparent;

        }

        &[hide] {
            opacity: 0;
            transition: opacity .5s ease-out;
        }

        img {
            width: 180px;
            height: 120px; /* width: 100%; */
            border-radius: 2px;
            /*box-shadow: 0 1px #28e0a8;*/
        }

        .store_name {
            margin-top: 2px;
            text-shadow: 0 2px 2px rgba(#000, .5);
        }

        .store_title {
            color: #fff;
            font-size: 16px;
            display: flex;

            .store_body {
                width: 50%;
            }
        }

        .ttl {
            width: 14px;
            height: 14px;
            background: url("../../assets/ydt.png") no-repeat;
            background-size: 100% 100%;
            margin-top: 5px;
            margin-right: 1vh;
            /*background-color: #23FFF9;*/
        }
    }
</style>
