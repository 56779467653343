import Cookies from 'js-cookie'

const PsTokenKey = 'ydt_ps_token'
const MsTokenKey = 'ydt_ms_token'

export function getPsToken() {
  return Cookies.get(PsTokenKey)
}

export function setPsToken(token) {
  return Cookies.set(PsTokenKey, token)
}

export function removePsToken() {
  return Cookies.remove(PsTokenKey)
}

export function getMsToken() {
  return Cookies.get(MsTokenKey)
}

export function setMsToken(token) {
  return Cookies.set(MsTokenKey, token)
}

export function removeMsToken() {
  return Cookies.remove(MsTokenKey)
}
