<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
    import './scss/base.scss';

    export default {
        components: {

        },
        data() {
            return {}
        },
        mounted() {

        }
    }
</script>
<style lang="scss">
    .body {
        &:before, &:after {
            z-index: 2;
            content: '';
            position: absolute;
            top: 0;
            display: block;
            width: 40%;
            height: 100%;
        }

        &:before {
            left: 0;
            background: linear-gradient(90deg, rgba(#020E14, .6) 0%, rgba(#020E14, 0) 100%);
        }

        &:after {
            right: 0;
            background: linear-gradient(270deg, rgba(#020E14, .6) 0%, rgba(#020E14, 0) 100%);
        }
    }
</style>
