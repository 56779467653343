<template>
    <div class="middle_part">
        <v-row class="tabs tac PFSC-R">
            <v-col :span="6">
                <div class="tab">
                    <div :style="{'color':tab == 0?'#FBDA66':'#00FFF4'}" class="tab_label poa hov"
                         @click="tabHandle(0)">可视化管理
                    </div>
                    <img v-if="tab == 0" :src="require('../../assets/xuanz.png')"/>
                    <img v-else :src="require('../../assets/wxuanz.png')"/>
                </div>
            </v-col>
            <v-col :span="6">
                <div class="tab">
                    <div :style="{'color':tab == 1?'#FBDA66':'#00FFF4'}" class="tab_label poa hov"
                         @click="tabHandle(1)">资产管理
                    </div>
                    <img v-if="tab == 1" :src="require('../../assets/xuanz.png')"/>
                    <img v-else :src="require('../../assets/wxuanz.png')"/>
                </div>
            </v-col>
            <v-col :span="6">
                <div class="tab">
                    <div :style="{'color':tab == 2?'#FBDA66':'#00FFF4'}" class="tab_label poa hov"
                         @click="tabHandle(2)">安全管理
                    </div>
                    <img v-if="tab == 2" :src="require('../../assets/xuanz.png')"/>
                    <img v-else :src="require('../../assets/wxuanz.png')"/>
                </div>
            </v-col>
            <v-col :span="6">
                <div class="tab">
                    <div :style="{'color':tab == 3?'#FBDA66':'#00FFF4'}" class="tab_label poa hov"
                         @click="tabHandle(3)">数据管理
                    </div>
                    <img v-if="tab == 3" :src="require('../../assets/xuanz.png')"/>
                    <img v-else :src="require('../../assets/wxuanz.png')"/>
                </div>
            </v-col>
        </v-row>
        <div class="yxqk PFSC-M">
            <div class="tac yxqk_title">
                运行情况
            </div>
            <v-row class="yxqk_height">
                <v-col span="12" class="yxqk_border" v-if="this.$store.state.ifview==false">
                    <v-row>
                        <v-col span="11" class="yxqk_icon">
                            <img :src="require('../../assets/sheb.png')"/>
                        </v-col>
                        <v-col span="12" offset="1" class="yxqk_font">
                            <div style="font-size:10px;margin-top: 10%;">设备数量</div>
                            <div style="font-size:34px;margin-top:10px;">{{rs.deviceCount}}<span style="font-size:10px;margin-left: 5px;">个</span></div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col span="12" v-if="this.$store.state.ifview==false">
                    <v-row>
                        <v-col span="11" class="yxqk_icon">
                            <img :src="require('../../assets/sj.png')"/>
                        </v-col>
                        <v-col span="12" offset="1" class="yxqk_font">
                            <div style="font-size:10px;margin-top: 10%;">累计运行时长</div>
                            <div style="font-size:34px;margin-top:10px;">{{rs.runTime}}<span style="font-size:10px;margin-left: 5px;">小时</span></div>
                        </v-col>
                    </v-row>
                </v-col>
                    <!-- <div>
        <button @click="init">发消息</button>
                            </div> -->
                     <v-col span="8" class="yxqk_border" v-if="this.$store.state.ifview==true">
                    <v-row>
                        <v-col span="11" class="yxqk_icon">
                            <img :src="require('../../assets/left.png')"/>
                        </v-col>
                        <v-col span="12" offset="1" class="yxqk_font">
                            <div style="font-size:10px;margin-top: 10%;">入园人流</div>
                            <div style="font-size:34px;margin-top:10px;">{{this.$store.state.inNum}}<span style="font-size:10px;margin-left: 5px;">人次</span></div>
                        </v-col>
                    </v-row>
                </v-col>
                 <v-col span="8"  class="yxqk_border" v-if="this.$store.state.ifview==true">
                    <v-row>
                        <v-col span="11" class="yxqk_icon">
                            <img :src="require('../../assets/mid.png')"/>
                        </v-col>
                        <v-col span="12" offset="1" class="yxqk_font">
                            <div style="font-size:10px;margin-top: 10%;">园区内总人数</div>
                            <div style="font-size:34px;margin-top:10px;">{{this.$store.state.sum}}<span style="font-size:10px;margin-left: 5px;">人次</span></div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col span="8" v-if="this.$store.state.ifview==true">
                    <v-row>
                        <v-col span="11" class="yxqk_icon">
                            <img :src="require('../../assets/right.png')"/>
                        </v-col>
                        <v-col span="12" offset="1" class="yxqk_font">
                            <div style="font-size:10px;margin-top: 10%;">出园人流</div>
                            <div style="font-size:34px;margin-top:10px;">{{ this.$store.state.outNum}}<span style="font-size:10px;margin-left: 5px;">人次</span></div>
                        </v-col>
                    </v-row>
                </v-col>


            </v-row>
        </div>
        <div class="manage_map">
            <bg-map :addrs="addrs" :curren-addr="currentAddr"></bg-map>
        </div>
    </div>
</template>
<script>
    import bgMap from './bgMap.vue'
    import {addrList ,runStatus,webcamlist} from "../../api/screen";
    import { getMsToken } from '../../utils/auth';
    import { createLogger } from 'vuex';


    let socket;


    export default {
        props: ['currentAddr', 'addrs'],
        components: {
            bgMap,
        },
        data() {
            return {
                inNum:0,
                outNum:0,
                sum:0,
                tab: 0,

                rs: {
                    deviceCount: 0,
                    runtime: 0
                },   //运行情况

            }
        },
            async created(){
                const _this = this;
                let {data} = await addrList();

                //存储当前场地的id
                _this.$store.commit('setAddrs', data);




          //在页面加载时读取sessionStorage里的状态信息
          if(JSON.parse(sessionStorage.getItem(_this.$store.state.addrId))!=null){

            _this.$store.state.inNum = JSON.parse(sessionStorage.getItem(_this.$store.state.addrId)).inNum;

            _this.$store.state.outNum = JSON.parse(sessionStorage.getItem(_this.$store.state.addrId)).outNum;
            _this.$store.state.sum = JSON.parse(sessionStorage.getItem(_this.$store.state.addrId)).sum;


          }




},

        mounted() {

},
        methods: {

                async initsocket(){

                this.user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {}
                let id = JSON.parse(sessionStorage.getItem("user")).id
                const _this = this;
                let {data} = await addrList();

                //存储当前场地的id
                _this.$store.commit('setAddrs', data);
                let addid=_this.$store.state.addrId
                    if (typeof (WebSocket) == "undefined") {
                        console.log("您的浏览器不支持WebSocket");

                    } else {


                        let temurl=process.env.VUE_APP_BASE_API.split('//')[1]
                        let envurl=temurl.split(':')[0]
                        let socketUrl = "ws://"+envurl+":8012/imserver/"+id+"/"+addid

                        if (socket != null) {
                        socket.close();
                        socket = null;
                        }
                        // 开启一个websocket服务

                        socket = new WebSocket(socketUrl);
                        //打开事件
                        socket.onopen = function () {
                        console.log("websocket已打开");

                        };
                        //  浏览器端收消息，获得从服务端发送过来的文本消息
                        socket.onmessage = function (msg) {
                        if(JSON.parse(msg.data).code==20000){
                        //注册设备成功
                        console.log(JSON.parse(msg.data))
                        console.log(JSON.parse(msg.data).msg)
                        // 判断该消息是否是该场地的统计信息，addid类似一个IP,与当前场地比较，如果是就接受
                        if(JSON.parse(msg.data).addid !=undefined && JSON.parse(msg.data).addid ==_this.$store.state.addrId){
                        console.log("进园人数====" + JSON.parse(msg.data).inNum)
                        console.log("出园人数====" + JSON.parse(msg.data).outNum)
                        console.log("总人数====" + JSON.parse(msg.data).sum)
                        //保存到浏览器中
                        sessionStorage.setItem(_this.$store.state.addrId,msg.data)

                        //保存到仓库中
                       _this.$store.state.nummessage=msg.data

                        //从浏览器缓存中取值
                        _this.$store.state.inNum = JSON.parse(sessionStorage.getItem(_this.$store.state.addrId)).inNum;
                        _this.$store.state.outNum = JSON.parse(sessionStorage.getItem(_this.$store.state.addrId)).outNum;
                        _this.$store.state.sum = JSON.parse(sessionStorage.getItem(_this.$store.state.addrId)).sum;

                        }

                        }else{
                            //注册设备失败
                            _this.$message.error(JSON.parse(msg.data).msg);
                        // this.$notify.error({
                        // title: '错误',
                        // message: '这是一条错误的提示消息'
                        // });
                            console.log(JSON.parse(msg.data).msg)
                        }




        };
                    //关闭事件
                         socket.onclose = function () {
                         console.log("websocket已关闭");
        };
                    //发生了错误事件
                    socket.onerror = function () {
                    console.log("websocket发生了错误");
                    }
                }
    },


            tabHandle(type) {
                this.tab = type;
            },
            async runStatus() {
                let {data} = await runStatus({addrId: this.currentAddr.id});
                this.rs = data;
            },
         async  getcamlistAndsendSocket(){
                const t=this;
                let param = {addrId: this.currentAddr.id, areaId: ''};

                let {data} = await webcamlist(param);
                //如果没有安装相机，则不显示人流统计界面
                if(data.length==0){
                 t.$store.state.ifview=false;
                }

                data.forEach(item => {

                    if(item.iscountcam){
                    //显示人流统计界面
                    t.$store.state.ifview=true;
                    t.initsocket()
                    }else{
                    t.$store.state.ifview=false;
                    }


                })
            }

        },
        watch:{
            'currentAddr': function () {
                if (this.currentAddr.id) {
                    this.runStatus();
                    //场地变化时且场地中有统计型相机的
                  this.getcamlistAndsendSocket();

                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    .middle_part {
        position: absolute;
        width: 37%;
        height: 100%;
        left: 32%;
        top: 12%;
        z-index: 20;
        .tabs {
            height: 7%;
            overflow: hidden;

            .tab_label {
                font-size: 14px;
                top: 20%;
                width: 100%;
                text-align: center;
                color: rgba(0, 255, 244, 1);
            }

            img {
                height: 45px;
            }
        }

        .yxqk {
            height: 15%;
            width: 100%;
            background: url("../../assets/bg_4.png") no-repeat;
            background-size: 100% 100%;
            color: #fff;
            .yxqk_title{
                font-size: 11px;
                font-weight: 500;
                color: #19ECFF;
                height:30px;
                top: 5px;
            }
            .yxqk_height{
                height: calc(100% - 30px);
            }
            .yxqk_border{
                height: 100%;
                border-right: 1px solid;
                border-image: linear-gradient(180deg, rgba(25, 236, 255, 0), rgba(25, 236, 255, 1), rgba(25, 236, 255, 0)) 1 1;
            }
            .yxqk_icon {
                text-align: right;
                top: 20px;
            }

            .yxqk_icon img {
                width: 40%;
            }

            .yxqk_font {
                color: #fff;
                line-height: 1.5em;
                font-size: 1em;
                padding-left: .2em;
            }
        }

        .manage_map{
            height: 60%;
            margin-top: 1vh;
            background: url("../../assets/bg_6.png") no-repeat;
            background-size: 100% 100%;
            padding: 11px;
        }
    }
</style>
