<template>
    <div class="rightpart df fdc ais bxz">
        <div class="rh">
            <div>{{nowDate}}</div>
            <div @click="jumtback">
                <img src="../../assets/icon_switch.svg"/>登录后台
            </div>
        </div>
        <div class="sstq">
            <div>
                <span class="vb">.</span><span style="padding-left: 0.5em;">实时天气</span>
            </div>
            <div class="temp txt_green num"><span class="temp_num"> {{weather.temp}}</span><span class="unit">℃</span>
                <span class="name">{{weather.text}}</span></div>
            <v-row>
                <v-col span="9" class="wind"><span class="circle"></span>{{weather.windDir}} · {{weather.windScale}}级
                </v-col>
                <v-col span="10" class="wind"><span class="circle"></span>相对湿度 · {{weather.humidity}}%</v-col>
            </v-row>
            <div class="nextseven">
                <div>
                    <span class="vb">.</span><span style="padding-left: 0.5em;">近7日天气情况</span>
                </div>
                <div class="nextseven_info">
                    <div style="width: 14%;" v-for="(item,index) in next7days">
                        <p class="n7w_week">{{item.statDateText}}</p>
                        <p class="n7w_time">{{item.dateLabel}}</p>

                        <p class="tac">
                            <i style="font-size:2.8em;" :class="item.iconClass"></i>
                        </p>
                        <p class="n7w_sit">{{item.textDay}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="cgqqx" v-if="isShow">
            <div>
                <span class="vb">.</span><span style="padding-left: 0.5em;">传感器曲线</span>
            </div>
            <div class="chart">
                <line-chart v-for="item in chartList" :data="item.data" :option="item.option"></line-chart>
            </div>
        </div>
    </div>
</template>
<script>
    import lineChart from "../../components/echarts/line_chart";
    import {weatherDaliy, weatherNow, chartLine} from "../../api/screen"

    export default {
        props: ['currentAddr', 'deviceParam'],
        components: {
            lineChart
        },
        data() {
            return {
                nowDate: null,
                datetimer: '',
                weather: {},
                chartList: [],
                allChartList: [],
                next7days: [],
                isShow:false,
                chartInterval: '',
                weatherInterval:'',
            }
        },
        mounted() {
            this.initDate();
            // this.createChart();
            this.weatherInterval = setInterval(()=>{
                this.getWeather();
            },3600000)
        },
        methods: {
            jumtback(){
                window.location.href = this.$back_url;
            },
            async getWeather() {
                const t = this;

                let param = {id: this.currentAddr.id};
                let {data} = await weatherNow(param);
                this.weather = data.now;
                weatherDaliy(param).then(res => {
                    t.next7days = res.data;
                    t.next7days.forEach(item => {
                        item.dateLabel = item.dateLabel.replace('/', '-');
                        item.statDateText = (t.fmtWeek(item.statDate));
                        item.iconClass = 'qi-' + item.iconDay;
                    })
                })
            },
            //星期转换
            fmtWeek(week) {
                // const weekDate = week.substr(0, 10)
                const weekFmt = '日一二三四五六'.charAt(new Date(week).getDay())
                return '周' + weekFmt;
            },
            initDate() {
                const t = this;
                t.datetimer = setInterval(() => {
                    t.nowDate = t.date2str(new Date(), 'yyyy/MM/dd hh:mm');
                }, 1000);
            },
            //日期转换
            date2str(x, y) {
                let z = {
                    y: x.getFullYear(),
                    M: x.getMonth() + 1,
                    d: x.getDate(),
                    h: x.getHours(),
                    m: x.getMinutes(),
                    s: x.getSeconds()
                };
                return y.replace(/(y+|M+|d+|h+|m+|s+)/g, function (v) {
                    return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-(v.length > 2 ? v.length : 2))
                });

            },
            //折线图日期转换   2021-09-25 00:00:00 > 09/25(六)
            fmtLineTime(time) {
                let date = this.date2str(new Date(time), "MM/dd")
                let week = '日一二三四五六'.charAt(new Date(date).getDay());
                return date + "(" + week + ")";

            },

           fetchData(param) {
                const t = this;
                clearInterval(t.chartInterval);
                t.chartList = [];
                t.allChartList1 = [];
                t.allChartList2 = [];
                param.keyNames.forEach((item, i) => {
                    if (item) {
                        chartLine({keyName: item, addrId: param.addrId, catId: param.catId}).then(res => {
                            let data = res.data;
                            let yData = [];        //y轴数据
                            let xData = [];      //x轴刻度
                            let sName = '';     //左边数据说明
                            let eName = '';     //右边数据说明
                            let unit = '';     // 单位
                            let splitNumber = 4;
                            let min = 0;
                            let max = 20;
                            switch (item) {
                                case 'ec':
                                    sName = 'EC值';
                                    eName = '（单位：mS/cm）';
                                    min = 0, max = 5, splitNumber = 3;
                                    break;
                                case 'light':
                                    sName = '光照强度';
                                    eName = '（单位：/lux）';
                                    min = 0, max = 10000, splitNumber = 5;
                                    break;
                                case 'temp':
                                    sName = '空气（土壤）温度';
                                    eName = '（单位：/℃）';
                                    unit = '℃', min = 0, max = 45, splitNumber = 3;
                                    break;
                                case 'humidity':
                                    sName = '空气（土壤）湿度';
                                    eName = '相对湿度';
                                    unit = '%', min = 0, max = 45, splitNumber = 3;
                                    break;
                                case 'ph':
                                    sName = 'PH值';
                                    eName = '';
                                    break;
                            }
                            let id = item + param.catId;       //初始化div的id，不能重复
                            data.forEach((v) => {
                                yData.push(v.keyValue);
                                xData.push(t.fmtLineTime(v.createTime));
                            })
                            let chartData = {
                                data: {
                                    yData: yData,
                                    xData: xData,
                                    sName: sName,
                                    eName: eName,
                                    unit: unit,
                                    id: id,
                                    splitNumber: splitNumber,
                                    min: min,
                                    max: max
                                }
                            }
                            if(t.allChartList1.length < 2){
                                t.allChartList1.push(chartData);
                            }else{
                                t.allChartList2.push(chartData);
                            }

                        })
                    }
                })
                t.$nextTick(()=>{
                    t.chartList = t.allChartList1;
                })
                t.chartInterval = setInterval(() => {
                    if (t.allChartList1.length + t.allChartList2.length > 2) {
                        if (t.chartList.length < 2) {
                            t.chartList = t.allChartList1;
                        } else {
                            t.chartList = t.allChartList2;
                        }
                    } else {
                        t.chartList = t.allChartList1;
                    }
                }, 30000);

            },
        },
        watch: {
            'currentAddr': function () {
                if (this.currentAddr.id) {
                    this.getWeather();
                }
            },
            'deviceParam': function () {
                this.isShow = this.deviceParam.isShow;
                if(this.deviceParam.isShow){
                    this.fetchData(this.deviceParam);
                }
            },
        }
    }
    //  天气图标  32
</script>
<style lang="scss">
    .rightpart {
        z-index: 20;
        position: absolute;
        padding-right: 20px;
        right: 0;
        top: 0;
        height: 100%;
        width: 30%;

        &::-webkit-scrollbar {
            display: none;
        }

        .vb {
            background-color: rgb(40, 224, 168);
            color: rgb(40, 224, 168);
            border-radius: 3px;
            width: 3px;
            height: 15px;
        }

        .rh {
            margin-top: 20px;
            z-index: 5;
            font-size: 20px;
            height: 10%;
            color: #28e0a8;
            text-align: right;

            img {
                width: 24px;
                vertical-align: middle;
                margin-right: 5px;

            }
        }

        .sstq {
            min-height: 29%;
            border: 1px solid #28e0a8;
            padding: 3px 5px;
            background-color: rgba(20, 37, 32, 0.7);
            color: #fff;

            .temp {
                font-size: 32px;
                line-height: .9em;
                padding-left: .2em;

                .unit {
                    margin-left: .25em;
                    vertical-align: super;
                    font-size: .5em;
                }

                .temp_num {
                    font-weight: 600;
                }

                .name {
                    padding-left: .2em;
                    font-size: 24px;
                    line-height: 1em;
                }
            }

            .wind {
                left: 16px;
                padding-left: 8px;
                font-size: 16px;

                .circle {
                    position: absolute;
                    right: 100%;
                    top: 50%;
                    margin-top: -.5em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 1.5em;
                    height: 1.5em;
                    font-size: 12px;
                    color: #28E0A8;
                    border-radius: 50%;
                    border: 1px dashed #28E0A8;
                    border-radius: 50%;
                    animation: rotate360 10s linear infinite both;

                    &:before {
                        content: '';
                        display: block;
                        width: .5em;
                        height: .5em;
                        border-radius: 50%;
                        background: #28E0A8;
                    }
                }
            }

            .nextseven {
                margin-top: 0.7em;
                .nextseven_info {
                    margin-left: 0.5vw;margin-top: 1.5vh;display: flex;
                }

                .n7w_week {
                    font-size: 1.1em;
                    font-weight: bold;
                    text-align: center;
                }

                .n7w_time {
                    font-size: 0.8em;
                    color: #fff;
                    opacity: 0.7;
                    text-align: center;
                }

                .n7w_sit {
                    font-size: 1.1em;
                    font-weight: 400;
                    text-align: center;
                }

                .ant-col-3 {
                    width: 14% !important;
                }
            }
        }

        .cgqqx {
            color: #fff;
            height: 51%;
            margin-top: 1vh;
            padding: 3px 5px;
            background-color: rgba(20, 37, 32, 0.7);
            border: 1px solid #28e0a8;

            .chart {
                width: 100%;
                height: 95%;
                margin-top: .2em;
                padding: 5px;
            }
        }

    }
</style>
