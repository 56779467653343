<template>
    <div class="container_map fwh" id="map" ref="map"></div>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
    import {initMap} from '../../utils/bdmap.js'
    export default {
        name: 'bgMap',
        props: {
            addrs: {type: Array, default: []},
            currentAddr:{type:Object},
        },
        data() {
            return {
                mapCenter: [113.168726, 23.037597],
                zoomSize: 16,
                view: null,
                data: [],
                map: null,
            }
        },
        methods: {
            initMap() {
                const self = this;
                let map = this.map = initMap({
                    id: 'map',
                    tilt: 60,
                    heading: 0,
                    zoom: self.zoomSize,
                    center: this.mapCenter,
                    skyColors: ['#f60', '#033'],
                    mapType: BMAP_EARTH_MAP,
                    style: 'darkStyle'
                });
                map.setDefaultCursor('grab');
                let myIcon;
                if (self.currentAddr && self.currentAddr.icon) {
                    myIcon = new BMapGL.Icon(self.currentAddr.icon, new BMapGL.Size(50, 60));
                } else {
                    myIcon = new BMapGL.Icon(require('../../../public/static/ic_marker_position.png'), new BMapGL.Size(50, 60));
                }
                self.addrs.forEach(item => {
                    self.addMarker(item, myIcon);
                })
            },
            addMarker(item, myIcon) {
                const t = this;
                let p = new BMapGL.Point(item.longitude, item.latitude);
                let marker = new BMapGL.Marker(p, {icon: myIcon})
                t.map.addOverlay(marker);
                marker.addEventListener('click', function (e) {
                    t.clickMarker(e, item)
                })

                // this.markers[item.attr.type].data.push(p)
            },
            clickMarker(e, item) {
                const t = this;
                let pos = e.target.getPosition();
                t.map.panTo(pos);
                let opts = {
                    width: 400,     // 信息窗口宽度
                    height: 400,     // 信息窗口高度
                    // message:"<div>设备数量:16</div><div>主控编号:AE86</div><div>在线设备:1</div><div>离线设备:15</div>"
                }
                let content = `
                    <div class="map_store_info tal pe">
                       <div class="store_title"><div class="ttl"></div>`+item.addrName+`</div>
                       <p class="store_name">设备数量：` + item.devCount + `</p>
                       <p class="store_name">主控编号：` + item.masterId + `</p>
                       <p class="store_name">在线设备：` + item.onlineCount + `</p>
                       <p class="store_name">离线设备：` + item.offlineCount + `</p>
                    </div>`;
                let infoWindow = new BMapGL.InfoWindow(content, opts);  // 创建信息窗口对象
                // let infoWindow = new BMapGL.InfoWindow("地址:", opts);  // 创建信息窗口对象
                t.map.openInfoWindow(infoWindow, pos);
                t._changeStore('addrId',item.id);
                t._changeStore('currentAddr',item);
            },
            markerIcon_BMap() {
                let size = new BMapGL.Size(48, 48);

                function convertBmapIcon(url) {
                    return new BMapGL.Icon(url, size)
                }

                let v = {}
                for (let i in this.markerIcon) {
                    let it = this.markerIcon[i]
                    typeof this.markerIcon[i] == 'string' ? v[i] = convertBmapIcon(it) : v[i] = it.map(iit => {
                        return convertBmapIcon(iit)
                    });
                }
                return v;
                /*return Object.keys(this.markerIcon).map(it=>{
                  return typeof it == 'string'? return convertBmapIcon(it) : it.map(iit=>{
                    return convertBmapIcon(iit)
                  })
                })*/
            }
        },
        mounted() {
            this.initMap()
        },
        watch: {
            'addrs': function () {
                const t = this;
                t.map.clearOverlays();
                let myIcon = new BMapGL.Icon(require('../../../public/static/ic_marker_position.png'), new BMapGL.Size(50, 60));
                this.addrs.forEach(item => {
                    if(item.id == t.$store.state.addrId){
                        if(item.icon){
                            myIcon = new BMapGL.Icon(item.icon, new BMapGL.Size(50, 60));
                        }
                        let p = new BMapGL.Point(item.longitude, item.latitude);
                        t.map.setCenter(p);
                    }
                    t.addMarker(item, myIcon);
                });
            },
        },
    }
</script>
<style lang="scss">
    .container_map {
        display: block;

        &:after {
            content: '';
            z-index: 1;
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            box-shadow: inset 0 5vw 20vw 10vw rgba(0, 0, 0, .6);
            // box-shadow: inset 0 50px 0 50px black; background: #fff; mix-blend-mode: multiply; filter: blur(100px);
        }

        .shadow {
            visibility: hidden;
        }
    }

    .anchorBL {
        opacity: 0;
    }

    .BMap_bubble_content {
        overflow: visible !important;
    }

    .BMap_bubble_pop {
        visibility: hidden;
    }

    .map_store_info {
        left: -30px;
        top: 220px;
        padding: 20px 16px 16px 16px;
        font-size: 15px;
        visibility: visible;
        color: #28e0a8;
        /*background: linear-gradient(0, rgba(0, 0, 0, .75), transparent);*/
        background: rgba(0, 0, 0, .75);
        border-radius: 8px;
        filter: drop-shadow(0 1px rgba(#28e0a8, 1));
        border: 1px solid #28e0a8;

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 100%;
            display: block;
            border-radius: 0 0 4px 0;
            transform: translate(-50%, -50%) rotate(45deg);
            border: 8px solid rgba(0, 0, 0, .75);
            border-top-color: transparent;
            border-left-color: transparent;

        }

        &[hide] {
            opacity: 0;
            transition: opacity .5s ease-out;
        }

        img {
            width: 180px;
            height: 120px; /* width: 100%; */
            border-radius: 2px;
            /*box-shadow: 0 1px #28e0a8;*/
        }

        .store_name {
            margin-top: 2px;
            text-shadow: 0 2px 2px rgba(#000, .5);
        }

        .store_title {
            color: #fff;
            font-size: 16px;
            display: flex;
        }

        .ttl {
            width: 2px;
            height: 14px;
            margin-top: 5px;
            margin-right: 1vh;
            background-color: #28e0a8;
        }
    }
</style>
