import Vue from 'vue'
import Vuex from 'vuex'
import getters from "./getters";
import user from './modules/user'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    currentAddr:{},
    addrId:'',
    isfull:false,
    ifview:'',
    inNum:0,
    outNum:0,
    sum:0,
    isshow:false,
    webcam: null,
    ezopenObj:{},
  },
  mutations: {
    setAddrId(state, addrId) {
      // console.log(formItem)
      state.addrId = addrId
    },
    setAddrs(state, addrs) {
      // console.log(formItem)
      state.addrs = addrs
    },
    setCurrentAddr(state,addr){
      state.currentAddr = addr
    },
    change(state, data){
      /*data = [name, value]*/
      state[data[0]] = data[1]
    }
  },
  actions: {
  },
  modules: {
    user
  },
  getters
})

export default store
