<template>
    <div id="body" class="body df fdc" v-if="show">
        <div class="pt_name" @dblclick="dblclick">
            <v-row>
                <v-col :span="8">
                    <div class="logo_manage">
                        <div style="min-height: 1.2em;padding-left: 60px;font-size:80px">
                            <img :src="currentAddr.screenLogo"/>
                        </div>
                    </div>
                </v-col>
                <v-col :span="8" class="tac SYST-H"><div style="padding: 15px;letter-spacing:6px">{{currentAddr.screenName}}</div></v-col>
                <v-col :span="8">
                    <v-row>
                        <v-col :span="12">
                            <div class="avatar hov">
                                <img :src="require('../../assets/avatar.png')"/>
                                <span @click="jumtback">后台登录</span>
                            </div>
                        </v-col>
                        <v-col :span="11">
                            <div class="aqtc hov" @click="loginOut">
                                <img :src="require('../../assets/tuic.png')"/><span>安全退出</span>
                            </div>
                            <!-- <screenfull class="right-menu-item hover-effect" />   -->
                    </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <left-part :current-addr="currentAddr"></left-part>
        <middle-part :addrs="addrs" :current-addr="currentAddr"></middle-part>
      
        
        <div class="main leftbottom_manage" v-if=$store.state.isshow >
            <EZUIKitJs  :token="$store.state.ezopenObj.accessToken" :url="$store.state.ezopenObj.url" />

        <span class="close-btn">关闭</span>
    </div>
        <right-part :current-addr="currentAddr"></right-part>
        <div class="bottom-warning" v-if="warningShow">
            <v-carousel autoplay :vertical="settings.vertical"
                        :autoplay-speed="60000"
                        :dots="settings.dots"
                        :arrow="settings.arrow">
                <v-carousel-item v-for="item in warningList">
                    <div class="demo-carousel">{{item}}</div>
                </v-carousel-item>
            </v-carousel>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import {mapState} from 'vuex'
    import leftPart from './leftpart'
    import rightPart from "./rightpart"
    import middlePart from './middlepart'
    import '../../scss/base.scss';
    import {getPublicToken, addrList , exitLogin} from "@/api/screen";
    import {getMsToken, removeMsToken, removePsToken, setMsToken} from "../../utils/auth";
    import {decryptDes} from "../../utils/crypto-js";
    import EZUIKitJs from '@/components/EZUIKitJs';
    import screenfull from 'screenfull'
 
 
    
        
        
        // window.onload = function(){ 
        // var divHeight = document.getElementById('chenqi').clientWidth//获取容器固定宽度
        //  var scrollWidth = document.getElementById('chenqi').scrollWidth//获取容器内容超出总宽度
        
        // var count = document.getElementById('chenqi').text().length;
        // console.log('标题长度',count)
        // var length = window.getComputedStyle(document.getElementById('chenqi'), null).getPropertyValue('length');
        //   console.log('标题长度',length)
        // console.log('dviHeight',divHeight)
        // console.log('字体改变前',document.getElementById("chenqi").style)
        // var i=1
        // while(scrollWidth > divHeight){
        
        // var el = document.getElementById('chenqi');
        // var style = window.getComputedStyle(el, null).getPropertyValue('font-size');
        //  var length = window.getComputedStyle(el, null).getPropertyValue('length');
        
            // var count = $('#chenqi').text().length;

        //   console.log('标题长度',count)
        // var fontSize = parseFloat(style);
        // el.style.fontSize = (fontSize -i) + 'px';
        // divHeight = document.getElementById('chenqi').clientWidth
        // i++

        // console.log('kkkkk',fontSize)
        //  console.log('字体改变后',document.getElementById("chenqi").style.fontSize)
        // // divHeight=document.getElementById("top").offsetHeight;
       

        // }
     

        // } 
    export default {
        name: 'manageScreen',
        components: {
            leftPart,
            rightPart,
            middlePart,
            EZUIKitJs,
            // Screenfull:()=> import('@/components/Screenfull')
      
         
         
        },
        data() {
            return {
                addrs: [],
                nowDate: null,
                datetimer: '',
                timer_id: 0,  // 倒计时 ID
                time: null,
                date: null,
                show: false,
                deviceParam: {},
                warningShow: true,
                warningInterval: '',
                reloadAddrInterval: '',
                settings: {                 //滚动设置
                    dots: 'none',
                    arrow: 'never',
                    vertical: true
                },
                warningList: [],
                
            }
        },
        methods: {
            dblclick(){
                // console.log('发生了双击事件')
                    if (!screenfull.enabled) {
                    this.$message.warning("不支持全屏！");
                    return false
                        }
                screenfull.toggle()
            },
                loginOut(){
                exitLogin().then(res=>{
                    removeMsToken();
                    console.log('99999',this.$router)
                    this.$router.push({path: '/login'});
                })
            },
            jumtback() {
                window.location.href = this.$back_url;
            },
            initDate() {
                const t = this;
                t.datetimer = setInterval(() => {
                    t.nowDate = t.date2str(new Date(), 'yyyy/MM/dd hh:mm');
                }, 1000);
            },
            //日期转换
            date2str(x, y) {
                let z = {
                    y: x.getFullYear(),
                    M: x.getMonth() + 1,
                    d: x.getDate(),
                    h: x.getHours(),
                    m: x.getMinutes(),
                    s: x.getSeconds()
                };
                return y.replace(/(y+|M+|d+|h+|m+|s+)/g, function (v) {
                    return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-(v.length > 2 ? v.length : 2))
                });

            },
            async getToken() {
                let body = {
                    "password": "DCvaE$%12J",
                    "username": "CloudDotFront"
                }
                let {data} = await getPublicToken(body)
                if (data.token) {
                    setMsToken(data.token);
                }
                this.show = true;
                this.getAddr();
            },
            async getAddr() {
                const t = this;
                clearInterval(t.warningInterval);
                clearInterval(t.reloadAddrInterval);
                t.warningShow = true;
                let {data} = await addrList();
                t.$store.commit('setAddrs', data);
                if (t.$route.query.addrid) {
                    t._changeStore('addrId', t.$route.query.addrid);
                    data.forEach(item => {
                        if (item.id == t.$route.query.addrid) {
                            t.$store.commit("setCurrentAddr", item)
                            if (!item.screenLogo) {
                                t.currentAddr.screenLogo = require('../../assets/logo.png');
                            }
                            if (!item.screenName) {
                                t.currentAddr.screenName = '里水市政管理数字平台';
                            }
                        }
                    })
                } else {
                    if (data.length > 0) {
                        t._changeStore('addrId', data[0].id);
                        t._changeStore("currentAddr", data[0]);
                        if (!data[0].screenLogo) {
                            t.currentAddr.screenLogo = require('../../assets/logo.png');
                        }
                        if (!data[0].screenName) {
                            t.currentAddr.screenName = '里水市政管理数字平台';
                        }
                    }

                }
                data.forEach(item => {
                    if (item.id == t.$route.query.addrid) {
                        t._changeStore("currentAddr", item);
                        if (!item.screenLogo) {
                            t.currentAddr.screenLogo = require('../../assets/logo.png');
                        }
                        if (!item.screenName) {
                            t.currentAddr.screenName = '里水市政管理数字平台';
                        }
                    }
                })
                t.addrs = data;
                if (!t.currentAddr.warning) {
                    t.warningInterval = setInterval(() => {
                        t.warningShow = false;
                    }, 120000)
                }
                t.reloadAddrInterval = setInterval(() => {
                    t.getAddr();
                }, 600000)
            },
            deviceEvent(param) {
                this.deviceParam = param;
            },
        },


        created() {
            removePsToken();
            this.initDate();
            if (this.$route.query.pc) {
                let pc = this.$route.query.pc;
                let mstoken = decryptDes(pc);
                setMsToken(mstoken);
            }
            if (!getMsToken()) {
                this.$router.push({path: '/login'})
            } else {
                this.getAddr();
                this.show = true;
            }
        },
        computed: {
            ...mapState([
                'currentAddr',
                'addrId',
            ]),
        },
        watch: {
            currentAddr: function () {
                if (!this.currentAddr.screenLogo) {
                    this.currentAddr.screenLogo = require('../../assets/logo.png');
                }
                if (!this.currentAddr.screenName) {
                    this.currentAddr.screenName = '里水市政管理数字平台';
                }
                this.warningList = [];
                this.warningList.push(this.currentAddr.warning);
            }
        },
    }
</script>
<style lang="scss" scoped>
    #body {
        /*background-color: rgba(2, 7, 35, 1);*/
        background: url("../../assets/bg_0.png") no-repeat;
        background-size: 100% 100%; 
    }

    .light {
        z-index: 1;
        position: relative;
        left: 50%;
        display: block;
        width: 100%;
        height: 15%;
        background: #56ff7a80;
        transform: translate(-50%, -75%);
        border-radius: 50%;
        filter: blur(20vh);
    }

    .body {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }

    .avatar {
        text-align: right;
        font-size: 14px;
        margin-top: 7%;
        right: -31%;
        z-index: 999;
        img {
            position: relative;
            top: 13px;
            margin: 0 6px;
            width: 35px;
        }
    }

    .pt_name {
        background: url("../../assets/bg_1.png") no-repeat;
        background-size: 100%;
        width: 100%;
        font-size: 3em;
        color: rgba(25, 236, 255, 1);
        position: absolute;
        z-index: 999;
        height: 12%;
    }

    .aqtc {
        width: 108px;
        height: 40px;
        background: linear-gradient(180deg, rgba(1, 125, 125, 0.51) 0%, rgba(98, 252, 252, 0.23) 100%);
        border-radius: 28px;
        border: 2px solid;
        margin-top: 10%;
        right: -40%;
        font-size: 12px;
        color: #19ECFF;
        text-align: center;

        img {
            top: 8px;
            margin: 0 4px;
            width: 20px;
        }

        span {
            top: 2px;
        }
    }

    .txt_green {
        color: #28E0A8;
    }

    .txt_cyan {
        color: #00D6D6;
    }

    .logo_manage {
        margin-top: 20px;
        height: auto;
        font-size: 50px;
        color: #28e0a8;
        line-height: 32px;
        font-family: "Segoe UI Emoji";

        img {
            vertical-align: middle;
            margin-right: 5px;
            max-height: 0.7em;
            height: 1.2em;
        }

        span {
            background: linear-gradient(to right, #28e0a8, #DDF4C7);
            -webkit-background-clip: text;
            color: transparent;
        }
    }

    .container {
        z-index: 5;
        padding: 0 40px 20px 40px;
    }

    .part {
        width: 100%;
    }

    .air {
        padding-bottom: 20px;
        height: 140px;
        line-height: 1;
        border-bottom: 1px solid rgba(#fff, .2);
        // +.farm{order:-1}
    }

    .weather {
        padding: 12px 0;
        width: 50%; /*height: 100px; border-bottom: 1px solid rgba(#fff,.2); */
    }

    .rain {
        width: 100%;

        .name {
            padding-left: 1em;
            font-size: 24px;
            line-height: 3em;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -.5em;
                display: block;
                height: 1em;
                width: .2em;
                background: #28E0A8;
                border-radius: .2em;
            }
        }
    }

    .three {
        display: block;
        background: rgba(#000, 0);
    }

    .qrcode {
        padding: 12px;
        display: block;
        width: 1em;
        height: 1em;
        font-size: 200px;
        line-height: 0;
        overflow: hidden;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 0 16px rgba(0, 0, 0, .2);

        img {
            width: 100%;
        }
    }

    .rain ~ .qrcode {
        bottom: 100px;
        border-radius: 50%;
    }

    @keyframes rotate360 {
        0% {
            transform: rotate(0);
            transform-origin: center;
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .rightpart-enter-active, .rightpart-leave-active, .leftpart-enter-active, .leftpart-leave-active {
        transition: all .5s ease-out;
    }

    .rightpart-enter, .rightpart-leave-to {
        transform: translateX(100%);
        opacity: 0;
    }

    .leftpart-enter, .leftpart-leave-to {
        transform: translateX(-100%);
        opacity: 0;
    }

    .bottom-warning {
        position: fixed;
        height: 5%;
        width: 100%;
        /*background-color: rgba(2, 61, 91, 0.5);*/
        color: #19ECFF;
        font-size: 17px;
        text-align: center;
        vertical-align: middle;
        bottom: 0;
        z-index: 99;
        line-height: 1.8em;
    }
</style>
<style lang="scss">
    .ant-message-notice-content {
        position: fixed !important;
    }
</style>

