<template>
    <div :style="{'height':height}">
        <v-row>
            <v-col span="11" class="yn tal">{{data.sName}}</v-col>
            <v-col span="11" class="yn2 tar">{{data.eName}}</v-col>
        </v-row>
        <div v-bind:id="data.id" style="width: 100%;height:100%;"></div>
    </div>
</template>
<script>

    //引入基本模板
    let echarts = require('echarts/lib/echarts')

    // 引入折线图等组件
    require('echarts/lib/component/grid');
    require('echarts/lib/chart/line');
    import { TooltipComponent } from 'echarts/components'
    echarts.use([TooltipComponent]);
    export default {
        props: {
            option:{
                default:null
            },
            data:{
                default:null
            },
            height:{
                default:'100%'
            }
        },
        name: 'lineChart',
        components:{
            TooltipComponent
        },
        data() {
            return {}
        },
        methods: {
            initChart() {
                const t = this;
                let chartDom = document.getElementById(t.data.id);
                let myChart = echarts.init(chartDom);
                let option;
                if(t.data.option){
                   option = t.option;
                }else{
                    option = {
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            nameGap:20,
                            data: t.data.xData,
                            axisTick:{
                                show:false
                            },
                            
                            splitLine: {
                                lineStyle: {
                                    width: 1,
                                    type: 'solid',
                                    color: 'rgba(255,255,255,0.15)'
                                },
                                show: true,
                            },
                            axisLabel: {
                                interval: 0,
                                rotate: 0.5,
                                textStyle:{
                                    color:'#29EEFF'
                                },
                                align:'center',
                                margin:20
                            },
                        },
                        yAxis: [{
                            type: 'value',
                            splitNumber:t.data.splitNumber,
                            boundaryGap:t.data.boundaryGap,
                            min:t.data.min,
                            max:t.data.max,
                            splitLine: {
                                lineStyle: {
                                    width: 1,
                                    type: 'solid',
                                    color: 'rgba(255,255,255,0.15)'
                                },
                                show:true,
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 1,
                                    type: 'solid',
                                },
                                
                                show: true,
                            },
                            axisLabel: {
                                formatter: '{value}'+t.data.unit,
                                textStyle:{
                                    color:'#29EEFF'
                                }
                            },
                        },{
                            name: '',
                            nameTextStyle:{
                                color:'#fff',
                                fontSize:12
                            },
                        }],
                        series: [{
                            data: t.data.yData,
                            type: 'line',
                            smooth:true,
                            symbolSize:4,
                            symbol:'circle',
                            itemStyle: {
                                color: {
                                    type: 'linear',
                                    colorStops: [{
                                        offset: 0.1, color: 'rgba(40, 224, 168, .3)' // 0% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                },
                                borderColor: t.data.lineColor,
                                borderWidth: 2,
                            },
                            lineStyle: {
                                color: {
                                    // type: 'linear',
                                    colorStops: [{
                                        offset: 0.1, color: t.data.lineColor // 0% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                },
                            },
                        }],
                        textStyle: {
                            fontSize: 12,
                        },
                        grid: {
                            top: "10%",
                            left: '10%',
                            right: '0%',
                            bottom: '0%',
                            //是否显示网格
                            show: false,
                            x: "0%",//x 偏移量
                            y: "0%", // y 偏移量
                            width: "75%", // 宽度
                            height: "75%",// 高度
                            //是否显示刻度标签
                            containLabel: true,
                        },
                        tooltip: {
                            show: true, // 是否显示
                            trigger: 'item', // 触发类型  'item'图形触发：散点图，饼图等无类目轴的图表中使用； 'axis'坐标轴触发；'none'：什么都不触发。
                            axisPointer: { // 坐标轴指示器配置项。
                                type: 'line', // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
                                axis: 'auto', // 指示器的坐标轴。
                                snap: true, // 坐标轴指示器是否自动吸附到点上
                            },
                            // showContent: true, //是否显示提示框浮层，默认显示。
                            triggerOn: 'mousemove|click', // 触发时机  'mouseover'鼠标移动时触发。     'click'鼠标点击时触发。  'mousemove|click'同时鼠标移动和点击时触发。
                            // enterable: false, // 鼠标是否可进入提示框浮层中，默认为false，如需详情内交互，如添加链接，按钮，可设置为 true。
                            renderMode: 'html', // 浮层的渲染模式，默认以 'html 即额外的 DOM 节点展示 tooltip；
                            backgroundColor: 'rgba(50,50,50,0.7)', // 提示框浮层的背景颜色。
                            borderColor: '#333', // 提示框浮层的边框颜色。
                            borderWidth: 0, // 提示框浮层的边框宽。
                            padding: 5, // 提示框浮层内边距，
                            textStyle: { // 提示框浮层的文本样式。
                                color: '#28e0a8',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontFamily: 'sans-serif',
                                fontSize: 14,
                            },
                            extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);', // 额外附加到浮层的 css 样式
                            confine: false, // 是否将 tooltip 框限制在图表的区域内。
                            // formatter: '{b} 的成绩是 {c}'
                            formatter: function(arg) {
                                return arg.data+t.data.unit;
                            }
                        },
                    };
                }
                option && myChart.setOption(option);
            },
        },
        mounted() {
            if(this.data){
                this.initChart();
            }
        }
    }
</script>
<style>
    .yn{font-size: 1.2em;position: absolute;top: 1vh;color: #fff;}
    .yn2{font-size: 1em;position: absolute;top: 1vh;right: 0;color: #fff;}
</style>
