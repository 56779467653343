<template>
    <div class="leftpart poa pe">
        <div class="logo">
            <div style="min-height: 1.2em;">
                <img :src="currentAddr.screenLogo"/>
<!--                <span>{{currentAddr.screenName}}</span>-->
            </div>
        </div>
        <div class="header df fdc jcc">
            <transition name="leftpart">
                <div class="device_list">
                    <div class="title">
                        <div class="cdmz">
                            <span class="vb">.</span>
                            <span>{{currentAddr.addrName}}</span>
                        </div>
                        <div class="device_sel pea bxz">
                            <div class="dslo">
                                <v-select
                                        size="lg"
                                        v-model="currentSite"
                                        style="width: 100%;background-color: rgba(40,224,168,.2);" :data="sites"
                                        @change="siteChange">
                                </v-select>
                            </div>
                            <div class="dsgap"></div>
                            <div class="dslt">
                                <v-select
                                        size="lg"
                                        v-model="currentDevice"
                                        style="width: 100%;background-color: rgba(40,224,168,.2);" :data="deviceTypes"
                                        @change="deviceChange">
                                </v-select>
                            </div>
                        </div>
                    </div>
                    <div class="title title_device">
                        <div v-if="device_list.length == 0" class="list_group"></div>
                        <VueSlickCarousel v-else ref="carousel" class="list_group pea bxz" v-bind="settings">
                            <div v-for="(item,index) in device_list" class="list_data_li">
                                <p :class="item.powerText == '电量正常'?'electricity':'electricity-no'">
                                    {{item.powerText}}</p>
                                <p class="fw">设备名称：{{item.title}}</p>
                                <p class="fw">设备分类：{{item.catIdDicText}}</p>
                                <p>
                                    <span class="fw">在线状态：</span>
                                    <span class="fl" v-if="item.isOn">在线</span>
                                    <span class="fr" v-else>离线</span>
                                </p>
                                <p><span class="fw">最近运行：</span><span class="fl">{{item.lastRun}}</span></p>
                            </div>
                        </VueSlickCarousel>
                        <div v-if="device_list.length > 0" class="badbg"></div>
                    </div>
                </div>
            </transition>
        </div>
        <div class="leftbottom pea bxz" v-if="webcamShow">
            <div v-if="webcamIndex > 0" class="web-pre" @click="clickPre">
                <div class="arrow-left"></div>
            </div>
            <div v-if="webcamIndex < webcams.length - 1" class="web-next" @click="clickNext">
                <div class="arrow-right"></div>
            </div>
            <div><span class="vb">.</span><span style="font-size: 13px;margin-left: 4px;">监控视频</span></div>
            <div class="capture" @click="capFull">
                <img :src="webcam.capture"/>
            </div>
        </div>
        <div v-if="ezopenShow">
            <EZUIKitJs :token="ezopenObj.accessToken" :url="ezopenObj.url" :full="ezopenObj.isfull"/>
        </div>
    </div>
</template>
<script>
    //carousel
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import EZUIKitJs from '@/components/EZUIKitJs'
    import {ezopen, areaList, deviceList, webcamlist, webCamUrl, listWithCount} from '@/api/screen'
    import {mapState} from "vuex";

    export default {
        props: ['currentAddr'],
        components: {
            VueSlickCarousel,
            EZUIKitJs
        },
        data() {
            return {
                ezopenShow: false,
                ezopenObj: {},
                webcamShow: false,
                webcam: null,
                currentSite: '',     //设备场地
                sites: [],
                addrs: [],
                settings: {                 //滚动设置
                    arrows: true,
                    dots: false,
                    rows: 1,
                    vertical: true,
                    slidesToScroll: 2,
                    slidesToShow: 2,
                    autoplay: true,
                    autoplaySpeed: 20000
                },
                deviceTypes: [],
                deviceTypesIndex: {},   //记录设备类型下标
                currentTypeIndex: 0,
                typeInterval: '',
                device_list: [],
                currentDevice: '',
                webcams: [],
                webcamIndex: 0,
                catIds:[]
            }
        },
        methods: {
            clickPre() {
                const t = this;
                t.webcamIndex--;
                t.webcam = t.webcams[t.webcamIndex];
                // let param = {id: t.webcams[t.webcamIndex].id};
                // ezopen(param).then(res => {
                //     if (res.data) {
                //         t.webcam = res.data;
                //     }
                // })
            },
            clickNext() {
                const t = this;
                t.webcamIndex++;
                t.webcam = t.webcams[t.webcamIndex];
                // let param = {id: t.webcams[t.webcamIndex].id};
                // ezopen(param).then(res => {
                //     if (res.data) {
                //         t.webcam = res.data;
                //     }
                // })
            },
            siteChange(value) {
                this.getDeviceList(this.currentDevice);
            },
            deviceChange(value) {
                this.getDeviceList(value);
            },
            async getDeviceType() {
                const t = this;
                clearInterval(t.typeInterval);
                t.deviceTypes = [];
                t.deviceTypesIndex = {};
                t.currentDevice = '';
                t.currentTypeIndex = 0;
                let param = {
                    addrId: this.$store.state.addrId,
                    type: 1
                }
                let param2 = {
                    addrId: this.$store.state.addrId,
                    type: 2
                }
                let {data} = await listWithCount(param);
                data.forEach((item, index) => {
                    if (index == 0) {
                        t.currentDevice = item.id;
                        t.currentTypeIndex = index;
                    }
                    t.deviceTypes.push({label: item.title, value: item.id});
                    t.deviceTypesIndex[item.id] = item;
                })
                listWithCount(param2).then(res => {
                    if (res.code == 0) {
                        res.data.forEach(item => {
                            t.deviceTypes.push({label: item.title, value: item.id});
                            t.deviceTypesIndex[item.id] = item;
                        })
                    }

                })
                if (t.deviceTypes.length > 1) {
                    t.typeInterval = setInterval(() => {
                        if (t.currentTypeIndex == data.length - 1) {
                            t.currentTypeIndex = 0;
                        } else {
                            t.currentTypeIndex++;
                        }
                        t.currentDevice = data[t.currentTypeIndex].id;
                    }, 1200000);          //20分钟切换一次
                }

            },
            async getArea(addrId) {
                const t = this;
                let param = {addrId: addrId};
                let {data} = await areaList(param);
                t.sites = [];
                data.forEach((item, index) => {
                    if (index == 0) {
                        t.currentSite = item.id;
                        t.getWebcamList(item.id);
                    }
                    let option = {
                        label: item.areaName,
                        value: item.id,
                    }
                    t.sites.push(option);
                })
                if (data.length == 0) {
                    t.currentSite = '';
                }
            },
            async getDeviceList(catId) {
                const t = this;
                t.device_list = [];
                if (!catId) return;
                let catIds = JSON.parse(JSON.stringify(this.catIds));
                catIds.push(catId);
                let params = {
                    addrId: this.$store.state.addrId,
                    areaId: this.currentSite,
                    catId:'',
                    catIds: catIds
                }
                let {data} = await deviceList(params)
                t.device_list = data;
                let dType = this.deviceTypesIndex[catId];
                if (dType) {
                    if (dType.title == '灌溉设备' || dType.title == '智能开关' || dType.title == '智能环卫' || dType.title == '智能照明') {
                        this.$emit("deviceevent", {isShow: false});
                    } else {
                        let keyNames = '';
                        dType.extraList.forEach(item => {
                            if (item.keyName) {
                                keyNames += item.keyName + ',';
                            }
                        })
                        keyNames = keyNames.split(',');
                        let chartParam = {
                            addrId: this.$store.state.addrId,
                            keyNames: keyNames,
                            catId: catId,
                            isShow: true
                        }
                        this.$emit("deviceevent", chartParam);
                    }
                }
            },
            async getWebcamList(v) {
                const t = this;
                t.webcamShow = false;
                t.webcamIndex = 0;
                let param = {addrId: this.$store.state.addrId, areaId: ''};
                let {data} = await webcamlist(param);
                t.webcams = data;
                if (data.length > 0) {
                    t.webcam = t.webcams[t.webcamIndex];
                    t.webcamShow = true;
                } else {
                    t.webcam = null;
                }
            },
            bigCapture() {
                this.$modal.info({
                    iconType: '',
                    content: '<img style="width: 100%;max-height: 1000px;" src="' + this.webcam.capture + '" />',
                    width: 1250,
                    okText: '关闭'
                })
            },

            //查看实时大屏
            capFull() {
                const t = this;
                let param = {id: t.webcam.id};
                ezopen(param).then(res => {
                    if (res.data) {
                        t.ezopenObj = res.data;
                        t.ezopenObj.isfull = true;
                        t.ezopenShow = true;
                    }
                })
            }
        },
        computed: {
            ...mapState([
                'isfull',
            ]),
        },
        mounted() {
            if (this.$route.query.addrid) {
                this.getArea(this.$route.query.addrid);
            }
        }
        ,
        created() {
        },
        watch: {
            'currentAddr': function () {
                if (this.currentAddr.id) {
                    const t = this;
                    t.getArea(this.currentAddr.id);
                    t.getDeviceType()
                    t.weatherInterval = setInterval(() => {
                        t.getWeather();
                    }, 3600000);
                }
            },
            'isfull': function () {
                if (!this.isfull) {
                    this.ezopenShow = false;
                }
            }
        },
    }
</script>
<style lang="scss">
    @media screen and (max-height: 1025px) {
        .title_device {
            margin-top: 2vh;
            height: 70%;
            max-height: 75%;
            position: relative;
        }
    }
    @media screen and (min-height: 1026px) {
        .title_device {
            margin-top: 2vh;
            height: 54%;
            max-height: 75%;
            position: relative;
        }
    }
    .leftpart {
        color: #fff;
        z-index: 20;
        padding-left: 20px;
        width: 23%;
        height: 100%;

        .logo {
            margin-top: 20px;
            height: 8%;
            font-size: 50px;
            color: #28e0a8;
            line-height: 32px;
            font-family: "Segoe UI Emoji";

            img {
                vertical-align: middle;
                margin-right: 5px;
                /*max-width: 2em;*/
                max-height: 1.2em;
                height: 1.2em;
            }

            span {
                background: linear-gradient(to right, #28e0a8, #DDF4C7);
                -webkit-background-clip: text;
                color: transparent;
            }

            .pt_name {
                font-size: 20px;
            }
        }

        .header {
            z-index: 5;
            height: auto;
            font-size: 50px;
            height: 50%;
            letter-spacing: 0em;;
            background: linear-gradient(to left, #F6FFD1 0%, #28E0A8 100%);
            -webkit-background-clip: text;
            color: transparent;
            filter: drop-shadow(0 4px 2px rgba(#000, .4));

            .sub {
                font-size: .35em;
                letter-spacing: .1em;
                font-weight: 100;
                text-transform: uppercase;
                background: inherit;
                opacity: .75;
            }
        }

        .device_list {
            height: 100%;

            .it {
                width: 100%;
            }

            .title {
                font-size: 20px;
                color: #28e0a8;

                .tar {
                    font-weight: normal;
                    opacity: .5;
                }

                span {
                    width: 100%;
                }

                .cdmz {
                    span {
                        margin-left: 5px;
                    }
                }

                .vb {
                    background-color: rgb(40, 224, 168);
                    color: rgb(40, 224, 168);
                    font-size: 15px;
                }

                .badbg {
                    position: absolute;
                    z-index: 5;
                    width: 99%;
                    height: 20%;
                    /*top: calc(87% - 1px);*/
                    left: calc(1% - 1px);
                    background: linear-gradient(to bottom, rgba(45, 74, 64, 0.65), rgba(45, 74, 64, 0.99) 45%);
                    filter: blur(1px);
                    bottom: 1px;
                }

                .device_sel {
                    display: flex;
                    margin-top: 2vh;

                    .ant-select-selection__clear {
                        display: none !important;
                    }

                    .ant-select-selection {
                        border-radius: 0px !important;
                        border: 2px solid #28e0a8 !important;
                        background-color: rgba(40, 224, 168, .2) !important;
                    }

                    .ant-select-selection__rendered {
                        color: white;
                    }

                    .ant-select-dropdown, .ant-select-dropdown-menu {
                        background-color: rgba(40, 224, 168, .2) !important;
                    }

                    .ant-select-search__field__placeholder, .ant-select-selection__placeholder {
                        color: #fff;
                    }

                    .ant-select-arrow {
                        left: 40% !important;
                        background: url("../../../src/assets/arrow-down.png") no-repeat center;
                        color: #28e0a8;

                        &:after {
                            content: '';
                            color: #28e0a8;
                        }
                    }

                    .dslo {
                        width: 46%;
                    }

                    .dsgap {
                        width: 8%;
                        opacity: 0;
                        height: 1vh;
                    }

                    .dslt {
                        width: 46%;
                    }
                }

                .list_group {
                    min-height: 100%;
                    width: 100%;
                    background: rgba(20, 37, 32, 0.7);
                    font-size: 14px;
                    padding: 10% 1% 0% 1%;
                    border: 1px solid #28e0a8;

                    .list_data_li {
                        background: rgba(#28e0a8, 0.2);
                        padding: 5px 2px;
                        position: relative;

                        .fw {
                            color: white;
                        }

                        .fr {
                            color: red;
                        }

                        .fl {
                            color: #28e0a8;
                        }
                    }

                    .electricity-no {
                        color: red;
                        position: absolute;
                        right: 5px;
                    }

                    .electricity {
                        position: absolute;
                        right: 10px;
                    }

                    .slick-list {
                        height: calc(190px + 35px) !important;
                    }

                    .slick-next {
                        right: 0%;
                        width: 100%;
                        top: calc(90% - 6px) !important;
                        background: url("../../../src/assets/da_up.svg") no-repeat center;
                        color: #28e0a8;
                        transform: rotate(180deg);
                        z-index: 6;

                        &:before {
                            content: '';
                        }

                        height: 40px;
                    }

                    .slick-prev {
                        height: 40px;
                        width: 100%;
                        left: 0%;
                        top: 7% !important;
                        background: url("../../../src/assets/da_up.svg") no-repeat center;
                        color: #28e0a8;

                        &:before {
                            content: '';
                        }
                    }
                }
            }
        }

        .leftbottom {
            overflow: hidden;
            height: 27%;
            border: 1px solid #28e0a8;
            padding: 5px;
            margin-top: 1vh;
            z-index: 10;

            .audio-controls {
                position: absolute;
                top: 78%;
                display: block;
            }

            .vb {
                background-color: rgb(40, 224, 168);
                color: rgb(40, 224, 168);
                font-size: 12px;
                border-radius: 4px;
            }

            .web-pre {
                width: 3em;
                height: 3em;
                background: rgba(0, 0, 0, 0.6);
                position: absolute;
                border-radius: 1em;
                color: #fff;
                top: 50%;
                left: 3%;
                z-index: 10;

                .arrow-left {
                    position: relative;
                    top: 27%;
                    left: 31%;
                    width: 20px;
                    height: 20px;
                    border-top: 3px solid #fff;
                    border-right: 3px solid #fff;
                    transform: rotate(-135deg);
                }
            }

            .web-next {
                width: 3em;
                height: 3em;
                background: rgba(0, 0, 0, 0.6);
                position: absolute;
                border-radius: 1em;
                color: #fff;
                top: 50%;
                right: 3%;
                z-index: 10;

                .arrow-right {
                    position: relative;
                    top: 27%;
                    left: 31%;
                    width: 20px;
                    height: 20px;
                    border-top: 3px solid #fff;
                    border-right: 3px solid #fff;
                    transform: rotate(45deg);
                }
            }
        }

        .capture {
            margin-top: 2vh;

            img {
                width: 100%;
                max-height: 19em;
            }
        }
    }

</style>

