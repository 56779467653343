<template>
    <div class="login">
        <div class="login-form">
            <div class="form-logo"><img :src="require('../../assets/logo.png')"></div>
            <v-form direction="vertical" :model="userForm" :rules="rules" ref="ruleForm">
                <v-form-item label="用户名" required prop="username">
                    <v-input type="text" v-model="userForm.username" placeholder="用户名"  size="large"></v-input>
                </v-form-item>
                <v-form-item label="密码" required prop="password">
                    <v-input type="password" v-model="userForm.password" placeholder="密码"  size="large"></v-input>
                </v-form-item>
                <v-form-item style="margin-top:24px">
                    <v-button type="primary" html-type="submit" style="background-color: rgba(2, 28, 57, 0.8);width: 100%;"
                              size="large" @click.prevent="submitForm('ruleForm')">登录
                    </v-button>
                </v-form-item>
            </v-form>
        </div>
    </div>
</template>
<script>
    import {login} from '@/api/screen'
    import {getMsToken, setMsToken} from "../../utils/auth";

    export default {
        data() {
            return {
                userForm: {},
                islogin: false,
                rules: {
                    username: [{
                        required: true,
                        message: '请输入用户名'
                    }],
                    password:[{
                        required: true,
                        message:'请输入密码'
                    }]
                }
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    const t = this;
                    if (valid) {
                        login(t.userForm).then(res => {
                            if(res.code == 0){
                                let token = res.data.token;
                                setMsToken(token);
                                 sessionStorage.setItem("user", JSON.stringify(res.data))  // 缓存用户信息
                                t.$router.push({path:'/manageScreen'})
                            }
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });

            }
        },
        created() {
            if(getMsToken()){
                this.$router.push({path:'/manageScreen'})
            }
        },
    }
</script>
<style lang="scss">
    .login {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        /*background: -moz-linear-gradient(top, rgb(24, 92, 155), rgb(63, 202, 161));*/
        
        /*background: -ms-linear-gradient(top, rgb(24, 92, 155), rgb(63, 202, 161));*/
        
        /*background: -o-linear-gradient(top, rgb(24, 92, 155), rgb(63, 202, 161));*/
        
        /*background: -webkit-linear-gradient(top, rgb(24, 92, 155), rgb(63, 202, 161));*/
        background: url("../../assets/bg_0.png") no-repeat;
        background-size: 100% 100%;

        .login-form {
            width: 25%;
            height: auto;
            margin: auto;
            position: relative;
            top: 25%;
            border-radius: 1em;
            background-color: rgba(2, 7, 35, 0.5);
            .ant-form-item-label label {
                font-size: 2em;
                color: rgba(0, 255, 244, 1);;
            }

            /*background: rgba(255, 255, 255, 1);*/
            padding: 2em 2em;

            .form-logo {
                text-align: center;
                margin-bottom: 2em;

                img {
                    height: 5em;
                }
            }
        }

    }

</style>
