import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'

//beauty组件
import 'vue-beauty/package/style/vue-beauty.min.css'
import vueBeauty from 'vue-beauty'
import {message} from 'vue-beauty'
Vue.use(vueBeauty)


Vue.config.productionTip = false
 
const api_root = process.env.VUE_APP_BASE_API + '/api/'


Vue.prototype.$back_url =  process.env.BACK_URL  ||  'http://test.admin.hmeltech.com'
// if(process.env.NODE_ENV == 'production'){
//     Vue.prototype.$back_url = 'https://admin.hmeltech.com' //正式服
// }

Vue.prototype.$message = message

Vue.prototype._get = function (url, data, success, error) {
    console.log("请求参数", data)
    let dataText = ''
    if (data) {
        for (let i in data) {
            dataText += `&${i}=${data[i]}`
        }
        dataText = '?' + dataText.slice(1)
    }
    axios.get(api_root + url + dataText).then(res => {
        // console.log("【请求成功】", res, success)
        return success ? success(res) : ''
    }).catch(res => {
        console.error(`【请求错误 - ${api_root + url + dataText}】`, res)
        return error ? error(res) : ''
    })
}


Vue.prototype._changeStore = function(storeName, setData){
    this.$store.commit('change', [storeName, setData])
}


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
