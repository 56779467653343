import { getMsToken, setMsToken, removeMsToken } from '@/utils/auth'
import { resetRouter } from '@/router'

const state = {
    token: getMsToken(),
    name: '',
    loginId: 0
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_LOGIN_ID: (state, user_id) => {
        state.loginId = user_id
    }

}

const actions = {
    // user login
    // login({ commit }, userInfo) {
    //     const { account, password } = userInfo
    //     return new Promise((resolve, reject) => {
    //         loginUser({ account: account.trim(), password: password }).then(response => {
    //             const { data } = response
    //             commit('SET_TOKEN', data.token)
    //             setToken(data.token)
    //             resolve()
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    // get user info
    // getLoginUser({ commit, state }) {
    //     return new Promise((resolve, reject) => {
    //         getUser(state.token).then(response => {
    //             const { data } = response
    //
    //             if (!data) {
    //                 reject('验证错误,请重新登录')
    //             }
    //             const { user_id,user_name } = data
    //             commit('SET_NAME', user_name || '')
    //             commit('SET_LOGIN_ID', user_id || 0)
    //             resolve(data)
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    // user logout
    // logout({ commit, state }) {
    //     commit('SET_TOKEN', '')
    //     commit('SET_NAME', '')
    //     commit('SET_LOGIN_ID', 0)
    //     removeToken()
    //     resetRouter()
    // },

    // remove token
    // resetToken({ commit }) {
    //     return new Promise(resolve => {
    //         commit('SET_TOKEN', '')
    //         removeToken()
    //         resolve()
    //     })
    // }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
